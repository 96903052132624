import React, { useState, useContext } from "react";
import {
  Button,
  Grid,
  useMediaQuery,
  useTheme,
  Box,
  Stepper,
  Step,
  StepLabel,
  CircularProgress,
  Backdrop
} from "@mui/material";

import AuthContext from "../../../context/AuthContext";

import IsoFields , { iso_fields } from "./IsoFields";
import BankFields , { bank_fields } from "./BankFieldsISO";

import { fetchData } from "../../../services/apiService";
import { createUserInKeycloak, checkUserExists } from "../../../services/userService";
import { initialFormData } from "./initialFormData";

const steps = ["ISO", "Bank Info"];

const FormComponent = ({ setSnackOpen, setMessageOpen, setSeverity }) => {
  const { token, acquirerData } = useContext(AuthContext);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [formData, setFormData] = useState(initialFormData);
  const [activeStep, setActiveStep] = useState(0);
  const [loading, setLoading] = useState(false);

  const filteredStep = ["iso", "bank"]; 

  const getFieldsForStep = (step) => {
    switch (step) {
      case "iso":
        return iso_fields;
      case "bank":
        return bank_fields;
      default:
        return [];
    }
  };

  const validateStep = (step) => {
    const currentStepFields = getFieldsForStep(step);
  
    for (let field of currentStepFields) {
      if (typeof field.required === 'function') {
        const isRequired = field.required(formData);
        if (isRequired && !formData[step]?.[field.name]) {
          return false;
        }
      } else if (field.required && !formData[step]?.[field.name]) {
        return false;
      }
    }

    return true; 
  };  
  
  const handleNext = () => {
    const currentStep = filteredStep[activeStep];
    if (validateStep(currentStep)) {
     
      setActiveStep((prevStep) => {
        if (activeStep < filteredStep.length - 1) {
          return prevStep + 1;
        }
        return prevStep;
      });
    } else {
      let errorMessage = "Please fill in all required fields!";
      setSeverity("error");
      setMessageOpen(errorMessage); 
      setSnackOpen(true); 
    }
  };

  const handleBack = () => {
    setActiveStep((prevStep) => {
      if (activeStep > 0) {
        return prevStep - 1;
      }
      return prevStep;
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      iso: {
        ...prevState.iso,
        [name]: value,
      },
    }));
  };
  
  const handleInputChangeBank = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      bank: {
        ...prevState.bank,
        [name]: value,
      },
    }));
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        acquirerName: acquirerData.acquirerName,
        acquirerID: acquirerData.acquirerId,
      };
  
      const { currencyCode, isoName, effectiveDate, email, ...restIso } = formData.iso;
      const formattedEffectiveDate = effectiveDate.replace(/-/g, "");

      const sanitizedUsername = isoName
        .toLowerCase()
        .replace(/\s+/g, "_")
        .replace(/[^a-z0-9_]/g, "");

      // Check if user already exists before creating ISO
      const existingUser = await checkUserExists({ username: sanitizedUsername, email });
      if (existingUser) {
        setSeverity("error");
        setMessageOpen("User already exists with this email or ISO Name!");
        setSnackOpen(true);
        return;
      }
  
      const payload = {
        version: "04.09.01",
        acquirerID: acquirerData.acquirerId,
        fiat: {
          currencyCode,
        },
        isoName: isoName,
        iso: {
          ...restIso,
          effectiveDate: formattedEffectiveDate,
        },
        bank: {
          ...formData.bank,
        },
        timeStamp: new Date().toISOString(),

      };      
  
      // create ISO
      const response = await fetchData({
        endpoint: "/enroll-iso",
        data: payload,
        headers,
        token,
      });

      let successMessage = `✅ ISO successfully saved! ISO ID: ${response.data.isoID}`;
      
      if (response.data.isoID) {
        // create user
        const userData = {
          username: sanitizedUsername,
          email: email,
          emailVerified: false,
          enabled: true,
          credentials: [
            {
              type: "password",
              value: "123456",
              temporary: true,
            },
          ],
          attributes: {
            "acquirer-rol-data": "ISO",
            "acquirer-id-data": acquirerData.acquirerId,
            "acquirer-name-data": acquirerData.acquirerName,
            "acquirer-id-iso-data": response.data.isoID,
            "acquirer-currency-data": currencyCode,
          },
        };
  
        try {
          await createUserInKeycloak(userData);
  
          successMessage += `\n👤 User successfully created! Check your email to log in!`;
        } catch (keycloakError) {
          console.error("Error creating user in Keycloak:", keycloakError);
          setSeverity("error");
          setMessageOpen(`ISO created, ISO ID: ${response.data.isoID}, but user not created because there is already a user with that email or name!`); 
          setSnackOpen(true); 
          return; 
        }
      }
  
      setMessageOpen(successMessage);
      setSeverity("success");
      setSnackOpen(true);
      setFormData(initialFormData);

    } catch (error) {
      let errorMessage = "ISO NOT saved!";
      if (error.request?.responseText) {
        try {
          const response = JSON.parse(error.request.responseText);
          if (response.detail) {
            errorMessage += `: ${response.detail}`;
          }
        } catch (parseError) {
          errorMessage += `: ${error.request.responseText}`;
        }
      }
      setSeverity("error");
      setMessageOpen(errorMessage);
      setSnackOpen(true);
    } finally {
      setLoading(false);
    }
  };
  
  const handleStepClick = (index) => {
    if (index < activeStep) {
      setActiveStep(index);
    } else {
      const currentStep = filteredStep[activeStep];
      if (validateStep(currentStep)) {
        setActiveStep(index); 
      } else {
        let errorMessage = "Please fill in all required fields!";
        setSeverity("error");
        setMessageOpen(errorMessage);
        setSnackOpen(true);
      }
    }
  };

  return (
    
    <Box sx={{ width: "100%", py: 4 }}>
      <Backdrop open={loading} sx={{ color: "#fff", zIndex: 1301 }}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <Stepper
        activeStep={activeStep}
        alternativeLabel
        sx={{ mb: 3, padding: 2, backgroundColor: '#f5f5f5', borderRadius: 2 }}
      >
        {steps.map((label, index) => (
          <Step key={index}>
            <StepLabel
              sx={{
                color: activeStep === index ? 'primary.main' : 'text.secondary',
                fontWeight: activeStep === index ? 'bold' : 'normal',
              }}
              onClick={() => handleStepClick(index)} 
            >
              {label}
            </StepLabel>
          </Step>
        ))}
      </Stepper>

      <form onSubmit={handleSubmit}>

        <Grid container spacing={isMobile ? 2 : 4} pb={isMobile ? "16px" : "32px"}>
          {activeStep === steps.indexOf("ISO") && <IsoFields formData={formData} handleInputChange={handleInputChange} />}
          {activeStep === steps.indexOf("Bank Info") && <BankFields formData={formData} handleInputChange={handleInputChangeBank} />}
        </Grid>

        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mt: 3 }}>
          <Box sx={{ display: "flex", gap: 2 }}>
            <Button disabled={activeStep === 0} onClick={handleBack}>
              Back
            </Button>
            {activeStep === steps.length - 1 ? (
              <Button variant="contained" color="primary" onClick={() => handleSubmit()}>
                Finish
              </Button>
            ) : (
              <Button variant="contained" onClick={handleNext}>
                Next
              </Button>
            )}
          </Box>
        </Box>

      </form>
    </Box>
  );
};

export default FormComponent;
