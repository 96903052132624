export const initialFormData = {
    acquirer: {
      iso: "",
      isoID: "",
      currency: "",
      holding: "",
      holdingId: "",
      group: "",
      groupId: "",
      name: "",
      store: "",
      address: "",
      neighborhood: "",
      city: "",
      merchantState: "",
      postalCode: "",
      mccID: "",
      merchantID: "",
      amountOther: "",
      terminal: {
        terminals: [{ terminalID: "", terminalType: "", terminalSerial: "" }],
      },
    },
    merchant: {
      legal_name: "",
      business_email: "",
      business_phone: "",
      business_type: "",
      taxpayer_id: "",
      incorporation_date: "",
      effective_date: "",
      amount_tx_processing: "",
      amount_tx_processing_tiers: "",
      percent_tx_processing: "",
      percent_tx_processing_tiers: "",
      amount_terminal_maintenance: "",
      amount_terminal_maintenance_tiers: "",
      terminal_maintenance_inactive: "",
      amount_terminal_setup: "",
      amount_terminal_setup_tiers: "",
    },
    bank: {
      bid: "",
      swift_code: "",
      account_number: "",
      address: "",
      routing_number: "",
      iban: "",
      branch_number: "",
      city: "",
      state: "",
      country_code: "",
      postal_code: "",
    },
    owner: [
      {
        first_name: "",
        last_name: "",
        middle_name: "",
        id: "",
        taxpayer_id: "",
        percentage: "",
        id_issuer: "",
        personal_email: "",
        personal_phone: "",
        address: "",
        city: "",
        state: "",
        country: "",
        postal_code: "",
        neighborhood: "",
        residency_time: "",
      },
    ],
  };
  