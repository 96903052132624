import axios from "axios";
import { SHA256, enc } from "crypto-js";

const API_BASE_URL = process.env.REACT_APP_API_ROUTE;

/**
 * Makes an API call to get endpoint details.
 * 
 * @param {string} endpoint - The URL endpoint (example: "/terminal-details").
 * @param {Object} data - The body of the request.
 * @param {Object} [headers] - Additional headers for the request.
 * @param {boolean} [useDefaultHash] -Defines whether the default hash should be generated automatically.
 * @returns {Promise<Object>} - The data returned by the API.
 */
export const fetchData = async ({
  endpoint,
  data,
  headers = {},
  token,
}) => {

  const url = `${API_BASE_URL}${endpoint}`;


  const dataString = JSON.stringify(data);
  const hash = SHA256(dataString + token).toString(enc.Base64);
  const userHash = SHA256(dataString).toString(enc.Base64);
  data.reqhashMAC = hash;
  data.userToken = userHash;

  try {
    const response = await axios.post(url, data, { headers });
    return response;
  } catch (error) {
    console.error(`Error fetching data from ${endpoint}:`, error);
    throw error; 
  }
};
