import React from "react";
import { Grid, TextField, FormControl, InputLabel, Select, MenuItem, Tooltip, IconButton, FormHelperText } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";

export const bank_fields = [
    { 
        label: "Bid", 
        name: "bid", 
        type: "text", 
        shrink: true, 
        maxLength: 8, 
        required: (formData) => formData.acquirer.iso === "True",
        helperText: "Bank identification code (unique identifier for the bank)" 
    },
    { 
        label: "SWIFT Code", 
        name: "swift_code", 
        type: "text", 
        shrink: true, 
        maxLength: 16, 
        required: (formData) => formData.acquirer.iso === "True",
        helperText: "International SWIFT/BIC code for wire transfers" 
    },
    { 
        label: "Account Number", 
        name: "account_number", 
        type: "text", 
        shrink: true, 
        maxLength: 16, 
        required: (formData) => formData.acquirer.iso === "True",
        helperText: "Merchant's bank account number" 
    },
    { 
        label: "Bank Address", 
        name: "address", 
        type: "text", 
        shrink: true, 
        maxLength: 32, 
        required: (formData) => formData.acquirer.iso === "True",
        helperText: "Physical address of the bank branch" 
    },
    { 
        label: "Routing Number", 
        name: "routing_number", 
        type: "text", 
        shrink: true, 
        maxLength: 9, 
        required: (formData) => formData.acquirer.iso === "True",
        helperText: "ABA routing number (US) or sort code (UK)" 
    },
    { 
        label: "IBAN", 
        name: "iban", 
        type: "text", 
        shrink: true, 
        maxLength: 40, 
        required: (formData) => formData.acquirer.iso === "True",
        helperText: "International Bank Account Number (required for international transfers)" 
    },
    { 
        label: "Branch Number", 
        name: "branch_number", 
        type: "text", 
        shrink: true, 
        maxLength: 8, 
        required: (formData) => formData.acquirer.iso === "True",
        helperText: "Bank branch identifier (if applicable)" 
    },
    { 
        label: "City", 
        name: "city", 
        type: "text", 
        shrink: true, 
        maxLength: 32, 
        required: (formData) => formData.acquirer.iso === "True",
        helperText: "City where the bank is located" 
    },
    { 
        label: "State", 
        name: "state", 
        type: "text", 
        shrink: true, 
        maxLength: 32, 
        required: (formData) => formData.acquirer.iso === "True",
        helperText: "State/Province where the bank is located" 
    },
    { 
        label: "Country Code", 
        name: "country_code", 
        type: "text", 
        shrink: true, 
        maxLength: 2, 
        required: (formData) => formData.acquirer.iso === "True",
        helperText: "2-letter country code (ISO 3166-1 alpha-2)" 
    },
    { 
        label: "Postal Code", 
        name: "postal_code", 
        type: "text", 
        shrink: true, 
        maxLength: 16, 
        required: (formData) => formData.acquirer.iso === "True",
        helperText: "Postal/ZIP code of the bank's location" 
    }
];

const BankFields = ({ formData, handleInputChange, submittedSteps, invalidFields }) => {
    console.log(submittedSteps)
  return (
    <>
      {bank_fields.map((field) => {
        const isRequired = typeof field.required === 'function' 
          ? field.required(formData) 
          : field.required;
        
        const fieldId = `bank.${field.name}`;
        const isError = invalidFields?.includes(fieldId) || 
                       (submittedSteps.has("bank") && isRequired && !formData.bank[field.name]);

        return (
          <Grid item xs={12} sm={3} key={field.name}>
            {field.type === "select" ? (
              <FormControl 
                fullWidth 
                required={isRequired}
                error={isError}
              >
                <InputLabel 
                  id={`${field.name}-label`}
                  sx={{
                    '& .MuiFormLabel-asterisk': {
                      color: 'red',
                      fontSize: '1.2rem',
                    }
                  }}
                >
                  {field.label}
                  {field.helperText && (
                    <Tooltip title={field.helperText} arrow>
                      <IconButton size="small" sx={{ padding: 0, marginLeft: '8px' }}>
                        <InfoIcon color="primary" fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  )}
                </InputLabel>
                <Select
                  labelId={`${field.name}-label`}
                  name={field.name}
                  label={field.label}
                  value={formData.bank[field.name] || ""}
                  onChange={handleInputChange}
                >
                  {field.options && field.options.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
                {isError && <FormHelperText error>This field is required</FormHelperText>}
              </FormControl>
            ) : (
              <TextField
                label={
                  <>
                    {field.label}
                    {field.helperText && (
                      <Tooltip title={field.helperText} arrow>
                        <IconButton size="small" sx={{ padding: 0, marginLeft: '4px' }}>
                          <InfoIcon color="primary" fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    )}
                  </>
                }
                name={field.name}
                value={formData.bank[field.name] || ""}
                onChange={handleInputChange}
                InputLabelProps={{ 
                  shrink: field.shrink,
                  sx: {
                    '& .MuiFormLabel-asterisk': {
                      color: 'red',
                      fontSize: '1.2rem',
                    }
                  }
                }}
                inputProps={{ maxLength: field.maxLength }}
                required={isRequired}
                error={isError}
                helperText={isError ? "This field is required" : ''}
                fullWidth
              />
            )}
          </Grid>
        );
      })}
    </>
  );
};

export default BankFields;
