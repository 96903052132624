import React from "react";
import {
  Modal,
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
} from "@mui/material";

const ResultModal = ({ open, onClose, successfulData, failedData }) => {

  const isFailedDataArray = Array.isArray(failedData);
  const isFailedDataString = typeof failedData === 'string';

  return (
    <Modal
      open={open}
      onClose={onClose}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          backgroundColor: "white",
          padding: 4,
          maxWidth: 600,
          maxHeight: "80vh",
          overflowY: "auto",
          borderRadius: 3,
        }}
      >
        {isFailedDataString && (
          <Typography variant="h6" color="red" sx={{ textAlign: "center", marginBottom: 2 }}>
            {failedData}
          </Typography>
        )}

        {isFailedDataArray && (
          <>
            {successfulData.length > 0 && (
              <>
                <Typography variant="h6" sx={{ textAlign: "center", color: "green", marginBottom: 2 }}>
                  Success
                </Typography>
                <TableContainer>
                  <Table aria-label="successful table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Status</TableCell>
                        <TableCell>Merchant</TableCell>
                        <TableCell>Message</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {successfulData.map((entry, index) => (
                        <TableRow key={index}>
                          <TableCell>Success</TableCell>
                          <TableCell>{entry.item.acquirer.merchantID}</TableCell>
                          <TableCell>Merchant Terminal successfully saved!</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </>
            )}

            {failedData.length > 0 && (
              <>
                <Typography variant="h6" sx={{ textAlign: "center", color: "red", marginBottom: 2 }}>
                  Errors
                </Typography>
                <TableContainer>
                  <Table aria-label="error table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Status</TableCell>
                        <TableCell>Merchant</TableCell>
                        <TableCell>Message</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                    {failedData.map((entry, index) => (
                      <TableRow key={index}>
                        <TableCell>Error</TableCell>
                        <TableCell>{entry.item?.acquirer?.merchantID || "N/A"}</TableCell>
                        <TableCell>
                          {entry.error && typeof entry.error === "object"
                            ? entry.error.response?.request?.response
                              ? entry.error.response.request.response.replace(/[{}"]/g, "")
                              : "Unknown Error"
                            : entry.message || entry.error || "Unknown Error"}
                        </TableCell>
                      </TableRow>
                    ))}

                    </TableBody>
                  </Table>
                </TableContainer>
              </>
            )}
          </>
        )}

        <Box sx={{ marginTop: 2, textAlign: "center" }}>
          <Button onClick={onClose} variant="contained">
            Close
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default ResultModal;
