import { fetchData } from "../apiService";

export const deleteTerminal = async (terminalIDs, token, acquirerData, merchantID) => {
  const endpoint = "/unenrollment";
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
    acquirerName: acquirerData.acquirerName,
    acquirerID: acquirerData.acquirerId
  };

  const data = {
    version: "04.09.01",
    acquirer: {
      merchantStore: "", 
      merchantID: merchantID ,
      terminalID: Array.isArray(terminalIDs) ? terminalIDs : [terminalIDs], 
      userID: acquirerData.userID || "US01primo.operator@merchantpartners.com",
      timeStamp: new Date().toISOString(), 
    },
  };

  try {
    const response = await fetchData({ endpoint, data, headers, token });
    return response.data; 
  } catch (error) {
    console.error("Error deleting terminal:", error);
    throw error;
  }
};
