import React from "react";
import { Grid, TextField, FormControl, InputLabel, Select, MenuItem, FormHelperText } from "@mui/material";

export const iso_fields = [
  { label: "ISO Name", name: "isoName", type: "text", shrink: true, maxLength: 16, required: true, }, 
  { label: "Email", name: "email", type: "text", shrink: true, maxLength: 32, required: true, }, 
  { label: "Currency Code", name: "currencyCode", type: "select", options: ["USD", "BRL", "MXN"], shrink: true, required: true},
  { label: "Address", name: "address", type: "text", shrink: true, maxLength: 32, required: true, },
  { label: "Neighborhood", name: "neighborhood", type: "text", shrink: true, maxLength: 32, required: false, help_text: "Village, District, Settlement"},
  { label: "City", name: "city", type: "text", shrink: true, maxLength: 32, required: true, help_text: "Municipality"},
  { label: "County", name: "county", type: "text", shrink: true, maxLength: 16, required: false, help_text: "County or Parish"},
  { label: "State", name: "state", type: "text", shrink: true, maxLength: 32, required: true, help_text: "State, Province, Department"},
  { label: "Postal Code", name: "postalCode", type: "text", shrink: true, maxLength: 16, required: true,},
  { label: "Country Code", name: "countryCode", type: "text", shrink: true, maxLength: 2, required: true,  },
  { label: "Amount TX Processing", name: "amountTxProcessing", type: "text", shrink: true, maxLength: 16, required: true,  },
  { label: "Amount TX Processing Tiers", name: "amountTxProcessingTiers", type: "text", shrink: true, maxLength: 24, required: false, },
  { label: "Percent TX Processing", name: "percentTxProcessing", type: "text", shrink: true, maxLength: 6, required: true, },
  { label: "Percent TX Processing Tiers", name: "percentTxProcessingTiers", type: "text", shrink: true, maxLength: 24, required: false,  },
  { label: "Amount Terminal Maintenance", name: "amountTerminalMaintenance", type: "text", shrink: true, maxLength: 16, required: true,  },
  { label: "Amount Terminal Maintenance Tiers", name: "amountTerminalMaintenanceTiers", type: "text", shrink: true, maxLength: 24, required: false,},
  { label: "Terminal Maintenance Inactive", name: "terminalMaintenanceInactive", type: "select", options: ["True", "False"], shrink: true, required: true, help_text: "Inactive terminals monthly maintenance: True = pay or False = don't pay"},
  { label: "Amount Terminal Setup", name: "amountTerminalSetup", type: "text", shrink: true,  maxLength: 16, required: true,},
  { label: "Amount Terminal Setup Tiers", name: "amountTerminalSetupTiers", type: "text", shrink: true, maxLength: 24, required: false, help_text: "May include multiple discount tiers by transaction volume"},
  { label: "Effective Date", name: "effectiveDate", type: "date", shrink: true, required: true, help_text: "Date at which all rates defined by the acquirer become effective"},
];

const IsoFields = ({ formData, handleInputChange }) => {
  return (
    <>
      {iso_fields.map((field) => (
        <Grid item xs={12} sm={3} key={field.name}>
          {field.type === "select" ? (
            <FormControl fullWidth required={field.required}>
              <InputLabel id={`${field.name}-label`}>{field.label}</InputLabel>
              <Select
                labelId={`${field.name}-label`}
                name={field.name}
                label={field.label}
                value={formData.iso?.[field.name] || ""}
                onChange={handleInputChange}
              >
                {field.options.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>{field.helperText}</FormHelperText>
            </FormControl>
          ) : (
            <TextField
              label={field.label}
              name={field.name}
              value={formData.iso?.[field.name] || ""}
              onChange={handleInputChange}
              InputLabelProps={{ shrink: field.shrink }}
              inputProps={{ maxLength: field.maxLength }}
              required={field.required}
              fullWidth
              type={field.type}
              helperText={field.helperText}
            />
          )}
        </Grid>
      ))}
    </>
  );
};

export default IsoFields;