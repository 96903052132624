import React, { useEffect } from "react";
import { Grid, TextField, FormControl, InputLabel, Select, MenuItem, Tooltip, IconButton, FormHelperText } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";

export const acquirer_fields = [
  { label: "ISO", name: "iso", type: "select", options: ["True", "False"], shrink: true, maxLength: 3, required: true, helperText: "Belongs to ISO or not" },
  { label: "ISO ID", name: "isoID", type: "text", shrink: true, maxLength: 4, required: (formData) => formData.acquirer.iso === "True", helperText: "ISO code" },
  { label: "Email", name: "email", type: "text", shrink: true, maxLength: 32, required: true, helperText: "Email Acquirer, also used to send user data" }, 
  { label: "Currency Code", name: "currency", type: "select", options: ["BRL", "USD", "MXN"], shrink: true, maxLength: 5, required: true, helperText: "Currency" },
  { label: "Amount Other", name: "amountOther", type: "select", options: ["True", "False"], shrink: true, maxLength: 5, required: true, helperText: "Whether other amounts are applicable" },
  { label: "Holding", name: "holding", type: "text", shrink: true, maxLength: 16, required: false, helperText: "Holding company name" },
  { label: "Holding ID", name: "holdingId", type: "text", shrink: true, maxLength: 16, required: false, helperText: "Holding company identifier" },
  { label: "Group", name: "group", type: "text", shrink: true, maxLength: 16, required: false, helperText: "Group name" },
  { label: "Group ID", name: "groupId", type: "text", shrink: true, maxLength: 16, required: false, helperText: "Group identifier" },
  { label: "Merchant Name", name: "name", type: "text", shrink: true, maxLength: 16, required: true, helperText: "DBA (Doing Business As) name" },
  { label: "Store", name: "store", type: "text", shrink: true, maxLength: 8, required: false, helperText: "Store identifier or name" },
  { label: "Address", name: "address", type: "text", shrink: true, maxLength: 32, required: true, helperText: "Street address of the merchant" },
  { label: "Merchant ID", name: "merchantID", type: "text", shrink: true, maxLength: 16, required: true, helperText: "Unique merchant identifier" },
  { label: "MCC ID", name: "mccID", type: "text", shrink: true, maxLength: 4, required: true, helperText: "Merchant Category Code (ISO18245 Compliant)" }, 
  { label: "Neighborhood", name: "neighborhood", type: "text", shrink: true, maxLength: 32, required: false, helperText: "Village, District, Settlement" },
  { label: "City", name: "city", type: "text", shrink: true, maxLength: 32, required: true, helperText: "Municipality" },
  { label: "State", name: "merchantState", type: "text", shrink: true, maxLength: 32, required: true, helperText: "State, Province, Department" },
  { label: "Postal Code", name: "postalCode", type: "text", shrink: true, maxLength: 16, required: true, helperText: "ZIP or postal code" }
];

const AcquirerFields = ({ formData, handleInputChange, acquirerData, submittedSteps, invalidFields }) => {
  useEffect(() => {
    if (acquirerData?.isoID) {
      handleInputChange({ target: { name: "iso", value: "True" } });
      handleInputChange({ target: { name: "isoID", value: acquirerData.isoID } });
    }
    if (acquirerData?.currency_code) {
      handleInputChange({ target: { name: "currency", value: acquirerData.currency_code } });
    }
  }, [acquirerData, handleInputChange]);

  return (
    <>
      {acquirer_fields.map((field) => {
        const isRequired = typeof field.required === "function" ? field.required(formData) : field.required;
        const fieldId = `acquirer.${field.name}`;
        const isError = invalidFields?.includes(fieldId) || 
                       (submittedSteps.has("acquirer") && isRequired && !formData.acquirer[field.name]);

        if (field.name === "isoID" && formData.acquirer.iso !== "True") {
          return null;
        }

        return (
          <Grid item xs={12} sm={3} key={field.name}>
            {field.type === "select" ? (
              <FormControl 
                fullWidth 
                required={isRequired} 
                disabled={(field.name === "iso" && !!acquirerData?.isoID) || (field.name === "currency" && !!acquirerData?.currency_code)}
                error={isError}
              >
                <InputLabel 
                  id={`${field.name}-label`}
                  sx={{
                    '& .MuiFormLabel-asterisk': {
                      color: 'red',
                      fontSize: '1.2rem',
                    }
                  }}
                >
                  {field.label}
                  {field.helperText && (
                    <Tooltip title={field.helperText} arrow>
                      <IconButton size="small" sx={{ padding: 0, marginLeft: '8px' }}>
                        <InfoIcon color="primary" fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  )}
                </InputLabel>
                <Select
                  labelId={`${field.name}-label`}
                  name={field.name}
                  label={field.label}
                  value={formData.acquirer[field.name] || ""}
                  onChange={handleInputChange}
                  disabled={(field.name === "iso" && !!acquirerData?.isoID) || (field.name === "currency" && !!acquirerData?.currency_code)}
                >
                  {field.options.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
                {isError && <FormHelperText error>This field is required</FormHelperText>}
              </FormControl>
            ) : (
              <TextField
                label={
                  <>
                    {field.label}
                    {field.helperText && (
                      <Tooltip title={field.helperText} arrow>
                        <IconButton size="small" sx={{ padding: 0, marginLeft: '4px' }}>
                          <InfoIcon color="primary" fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    )}
                  </>
                }
                name={field.name}
                value={formData.acquirer[field.name] || ""}
                onChange={handleInputChange}
                InputLabelProps={{ 
                  shrink: field.shrink,
                  sx: {
                    '& .MuiFormLabel-asterisk': {
                      color: 'red',
                      fontSize: '1.2rem',
                    }
                  }
                }}
                inputProps={{ maxLength: field.maxLength }}
                required={isRequired}
                error={isError}
                helperText={isError ? "This field is required" : ""}
                fullWidth
                disabled={field.name === "isoID" && !!acquirerData?.isoID}
              />
            )}
          </Grid>
        );
      })}
    </>
  );
};

export default AcquirerFields;