import React from "react";
import { Grid, TextField, FormControl, InputLabel, Select, MenuItem, Tooltip, IconButton, Box, Typography, Divider, FormHelperText } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import DeleteIcon from "@mui/icons-material/Delete";

export const fields_owners = [
    { 
        label: "First Name", 
        name: "first_name", 
        type: "text", 
        shrink: true, 
        maxLength: 16, 
        required: (formData) => formData.acquirer.iso === "True",
        helperText: "Owner's legal first name (as on government ID)" 
    },
    { 
        label: "Last Name", 
        name: "last_name", 
        type: "text", 
        shrink: true, 
        maxLength: 16, 
        required: (formData) => formData.acquirer.iso === "True",
        helperText: "Owner's legal last name (as on government ID)" 
    },
    { 
        label: "Middle Name", 
        name: "middle_name", 
        type: "text", 
        shrink: true, 
        maxLength: 16, 
        required: (formData) => formData.acquirer.iso === "True",
        helperText: "Owner's middle name or initial (if applicable)" 
    },
    { 
        label: "ID", 
        name: "id", 
        type: "text", 
        shrink: true, 
        maxLength: 16, 
        required: (formData) => formData.acquirer.iso === "True",
        helperText: "Government-issued identification number" 
    },
    { 
        label: "Taxpayer ID", 
        name: "taxpayer_id", 
        type: "text", 
        shrink: true, 
        maxLength: 16, 
        required: (formData) => formData.acquirer.iso === "True",
        helperText: "SSN, CPF, or equivalent tax identification number" 
    },
    { 
        label: "Percentage", 
        name: "percentage", 
        type: "text", 
        shrink: true, 
        maxLength: 3, 
        required: (formData) => formData.acquirer.iso === "True",
        helperText: "Ownership percentage (0-100 without % symbol)" 
    },
    { 
        label: "ID Issuer", 
        name: "id_issuer", 
        type: "text", 
        shrink: true, 
        maxLength: 16, 
        required: (formData) => formData.acquirer.iso === "True",
        helperText: "Authority that issued the ID (e.g., DMV, Federal Govt)" 
    },
    { 
        label: "Personal Email", 
        name: "personal_email", 
        type: "text", 
        shrink: true, 
        maxLength: 16, 
        required: (formData) => formData.acquirer.iso === "True",
        helperText: "Owner's primary contact email address" 
    },
    { 
        label: "Personal Phone", 
        name: "personal_phone", 
        type: "text", 
        shrink: true, 
        maxLength: 16, 
        required: (formData) => formData.acquirer.iso === "True",
        helperText: "Owner's primary contact phone number with country code" 
    },
    { 
        label: "Address", 
        name: "address", 
        type: "text", 
        shrink: true, 
        maxLength: 32, 
        required: (formData) => formData.acquirer.iso === "True",
        helperText: "Owner's primary residential street address" 
    },
    { 
        label: "City", 
        name: "city", 
        type: "text", 
        shrink: true, 
        maxLength: 16, 
        required: (formData) => formData.acquirer.iso === "True",
        helperText: "City of primary residence" 
    },
    { 
        label: "State", 
        name: "state", 
        type: "text", 
        shrink: true, 
        maxLength: 16, 
        required: (formData) => formData.acquirer.iso === "True",
        helperText: "State/Province of primary residence" 
    },
    { 
        label: "Country", 
        name: "country", 
        type: "text", 
        shrink: true, 
        maxLength: 2, 
        required: (formData) => formData.acquirer.iso === "True",
        helperText: "2-letter country code of residence (ISO 3166-1)" 
    },
    { 
        label: "Postal Code", 
        name: "postal_code", 
        type: "text", 
        shrink: true, 
        maxLength: 16, 
        required: (formData) => formData.acquirer.iso === "True",
        helperText: "ZIP/Postal code of primary residence" 
    },
    { 
        label: "Neighborhood", 
        name: "neighborhood", 
        type: "text", 
        shrink: true, 
        maxLength: 32, 
        required: (formData) => formData.acquirer.iso === "True",
        helperText: "District or locality within the city" 
    },
    { 
        label: "Residency Time", 
        name: "residency_time", 
        type: "text", 
        shrink: true, 
        maxLength: 4, 
        required: (formData) => formData.acquirer.iso === "True",
        helperText: "Years at current residence (whole numbers only)" 
    }
];

const OwnersFields = ({ 
  formData, 
  handleOwnerChange, 
  handleDeleteOwner,
  submittedSteps,
  invalidFields
}) => {
  return (
    <>
      {formData.owner.map((owner, index) => {
        const ownerId = `owner-${index}`;
        
        return (
          <Grid item xs={12} key={index} sx={{ mt: 2 }}>
            <Grid container spacing={3} alignItems="center">
              <Grid item xs={12}>
                <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
                  <Typography variant="h6">Owner {index + 1}</Typography>
                  <Tooltip title="Remove Owner" arrow>
                    <IconButton
                      color="error"
                      onClick={() => handleDeleteOwner(index)}
                      disabled={formData.owner.length === 1}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </Box>
                <Divider sx={{ mb: 2 }} />
              </Grid>

              {fields_owners.map((field) => {
                const isRequired = field.required(formData);
                const fieldId = `owner.${index}.${field.name}`;
                const isError = invalidFields?.includes(fieldId) || 
                               (submittedSteps.has("owner") && isRequired && !owner[field.name]);

                return (
                  <Grid item xs={12} sm={3} key={field.name}>
                    {field.type === "select" ? (
                      <FormControl 
                        fullWidth 
                        required={isRequired}
                        error={isError}
                      >
                        <InputLabel 
                          id={`${field.name}-label-${index}`}
                          sx={{
                            '& .MuiFormLabel-asterisk': {
                              color: 'red',
                              fontSize: '1.2rem',
                            }
                          }}
                        >
                          {field.label}
                          {field.helperText && (
                            <Tooltip title={field.helperText} arrow>
                              <IconButton size="small" sx={{ padding: 0, marginLeft: '8px' }}>
                                <InfoIcon color="primary" fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          )}
                        </InputLabel>
                        <Select
                          labelId={`${field.name}-label-${index}`}
                          name={field.name}
                          label={field.label}
                          value={owner[field.name] || ""}
                          onChange={(e) => handleOwnerChange(index, field.name, e.target.value)}
                        >
                          {field.options.map((option) => (
                            <MenuItem key={option} value={option}>
                              {option}
                            </MenuItem>
                          ))}
                        </Select>
                        {isError && <FormHelperText error>This field is required</FormHelperText>}
                      </FormControl>
                    ) : (
                      <TextField
                        label={
                          <>
                            {field.label}
                            {field.helperText && (
                              <Tooltip title={field.helperText} arrow>
                                <IconButton size="small" sx={{ padding: 0, marginLeft: '4px' }}>
                                  <InfoIcon color="primary" fontSize="small" />
                                </IconButton>
                              </Tooltip>
                            )}
                          </>
                        }
                        name={field.name}
                        value={owner[field.name] || ""}
                        onChange={(e) => handleOwnerChange(index, field.name, e.target.value)}
                        InputLabelProps={{ 
                          shrink: field.shrink,
                          sx: {
                            '& .MuiFormLabel-asterisk': {
                              color: 'red',
                              fontSize: '1.2rem',
                            }
                          }
                        }}
                        inputProps={{ maxLength: field.maxLength }}
                        fullWidth
                        required={isRequired}
                        error={isError}
                        helperText={isError ? "This field is required" : ''}
                      />
                    )}
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        );
      })}
    </>
  );
};

export default OwnersFields;

