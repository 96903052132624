import React from "react";
import { Grid, TextField, FormControl, InputLabel, Select, MenuItem, Tooltip, IconButton, FormHelperText } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";

export const merchant_fields = [
    { 
        label: "Legal Name", 
        name: "legal_name", 
        type: "text", 
        shrink: true, 
        maxLength: 16, 
        required: (formData) => formData.acquirer.iso === "True", 
        helperText: "Official legal name of the business" 
    },
    { 
        label: "Business Email", 
        name: "business_email", 
        type: "text", 
        shrink: true, 
        maxLength: 16, 
        required: (formData) => formData.acquirer.iso === "True", 
        helperText: "Primary contact email for the business" 
    },
    { 
        label: "Business Phone", 
        name: "business_phone", 
        type: "text", 
        shrink: true, 
        maxLength: 16, 
        required: (formData) => formData.acquirer.iso === "True", 
        helperText: "Primary business contact phone number" 
    },
    { 
        label: "Business Type", 
        name: "business_type", 
        type: "text", 
        shrink: true, 
        maxLength: 16, 
        required: (formData) => formData.acquirer.iso === "True", 
        helperText: "Type of business (e.g., LLC, Corporation, Sole Proprietorship)" 
    },
    { 
        label: "Taxpayer ID", 
        name: "taxpayer_id", 
        type: "text", 
        shrink: true, 
        maxLength: 16, 
        required: (formData) => formData.acquirer.iso === "True", 
        helperText: "Tax identification number (e.g., EIN, CNPJ, RFC)" 
    },
    { 
        label: "Incorporation Date", 
        name: "incorporation_date", 
        type: "date", 
        shrink: true, 
        maxLength: 16, 
        required: (formData) => formData.acquirer.iso === "True", 
        helperText: "Date when the business was legally established" 
    },
    { 
        label: "Effective Date", 
        name: "effective_date", 
        type: "date", 
        shrink: true, 
        maxLength: 16, 
        required: (formData) => formData.acquirer.iso === "True", 
        helperText: "Date when the merchant agreement becomes effective" 
    },
    { 
        label: "Amount TX MDR", 
        name: "amount_tx_processing", 
        type: "text", 
        shrink: true, 
        maxLength: 32, 
        required: (formData) => formData.acquirer.iso === "True", 
        helperText: "Fixed MDR (Merchant Discount Rate) per transaction" 
    },
    { 
        label: "Amount TX MDR Tiers", 
        name: "amount_tx_processing_tiers", 
        type: "text", 
        shrink: true, 
        maxLength: 32, 
        required: (formData) => formData.acquirer.iso === "True", 
        helperText: "Tiered MDR amounts based on transaction volume" 
    },
    { 
        label: "Percent TX MDR", 
        name: "percent_tx_processing", 
        type: "text", 
        shrink: true, 
        maxLength: 6, 
        required: (formData) => formData.acquirer.iso === "True", 
        helperText: "Percentage-based MDR per transaction" 
    },
    { 
        label: "Percent TX MDR Tiers", 
        name: "percent_tx_processing_tiers", 
        type: "text", 
        shrink: true, 
        maxLength: 32, 
        required: (formData) => formData.acquirer.iso === "True", 
        helperText: "Tiered percentage-based MDR based on transaction volume" 
    },
    { 
        label: "Amount Terminal Maintenance", 
        name: "amount_terminal_maintenance", 
        type: "text", 
        shrink: true, 
        maxLength: 32, 
        required: (formData) => formData.acquirer.iso === "True", 
        helperText: "Fixed monthly terminal maintenance fee" 
    },
    { 
        label: "Amount Terminal Maintenance Tiers", 
        name: "amount_terminal_maintenance_tiers", 
        type: "text", 
        shrink: true, 
        maxLength: 32, 
        required: (formData) => formData.acquirer.iso === "True", 
        helperText: "Tiered terminal maintenance fees based on usage" 
    },
    { 
        label: "Terminal Maintenance Inactive", 
        name: "terminal_maintenance_inactive", 
        type: "select", 
        options: ["True", "False"], 
        shrink: true, 
        maxLength: 5, 
        required: (formData) => formData.acquirer.iso === "True", 
        helperText: "Whether terminal maintenance fees are waived for inactive terminals" 
    },
    { 
        label: "Amount Terminal Setup", 
        name: "amount_terminal_setup", 
        type: "text", 
        shrink: true, 
        maxLength: 32, 
        required: (formData) => formData.acquirer.iso === "True", 
        helperText: "One-time terminal setup fee" 
    },
    { 
        label: "Amount Terminal Setup Tiers", 
        name: "amount_terminal_setup_tiers", 
        type: "text", 
        shrink: true, 
        maxLength: 32, 
        required: (formData) => formData.acquirer.iso === "True", 
        helperText: "Tiered terminal setup fees based on quantity" 
    },
];

const MerchantFields = ({ formData, handleInputChange, submittedSteps, invalidFields }) => {
  return (
    <>
      {merchant_fields.map((field) => {
        const isRequired = typeof field.required === 'function' 
          ? field.required(formData) 
          : field.required;
        
        const fieldId = `merchant.${field.name}`;
        const isError = invalidFields?.includes(fieldId) || 
                       (submittedSteps.has("merchant") && isRequired && !formData.merchant[field.name]);

        return (
          <Grid item xs={12} sm={3} key={field.name}>
            {field.type === "select" ? (
              <FormControl 
                fullWidth 
                required={isRequired}
                error={isError}
              >
                <InputLabel 
                  id={`${field.name}-label`}
                  sx={{
                    '& .MuiFormLabel-asterisk': {
                      color: 'red',
                      fontSize: '1.2rem',
                    }
                  }}
                >
                  {field.label}
                  {field.helperText && (
                    <Tooltip title={field.helperText} arrow>
                      <IconButton size="small" sx={{ padding: 0, marginLeft: '8px' }}>
                        <InfoIcon color="primary" fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  )}
                </InputLabel>
                <Select
                  labelId={`${field.name}-label`}
                  name={field.name}
                  label={field.label}
                  value={formData.merchant[field.name] || ""}
                  onChange={handleInputChange}
                >
                  {field.options.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
                {isError && <FormHelperText error>This field is required</FormHelperText>}
              </FormControl>
            ) : field.type === "date" ? (
              <TextField
                label={
                  <>
                    {field.label}
                    {field.helperText && (
                      <Tooltip title={field.helperText} arrow>
                        <IconButton size="small" sx={{ padding: 0, marginLeft: '4px' }}>
                          <InfoIcon color="primary" fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    )}
                  </>
                }
                name={field.name}
                type="date"
                value={formData.merchant[field.name] || ""}
                onChange={handleInputChange}
                InputLabelProps={{ 
                  shrink: true,
                  sx: {
                    '& .MuiFormLabel-asterisk': {
                      color: 'red',
                      fontSize: '1.2rem',
                    }
                  }
                }}
                required={isRequired}
                error={isError}
                helperText={isError ? "This field is required" : ''}
                fullWidth
                InputProps={{
                  sx: {
                    '&.Mui-error': {
                      borderColor: 'red',
                    }
                  }
                }}
              />
            ) : (
              <TextField
                label={
                  <>
                    {field.label}
                    {field.helperText && (
                      <Tooltip title={field.helperText} arrow>
                        <IconButton size="small" sx={{ padding: 0, marginLeft: '4px' }}>
                          <InfoIcon color="primary" fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    )}
                  </>
                }
                name={field.name}
                value={formData.merchant[field.name] || ""}
                onChange={handleInputChange}
                InputLabelProps={{ 
                  shrink: field.shrink,
                  sx: {
                    '& .MuiFormLabel-asterisk': {
                      color: 'red',
                      fontSize: '1.2rem',
                    }
                  }
                }}
                inputProps={{ maxLength: field.maxLength }}
                required={isRequired}
                error={isError}
                helperText={isError ? "This field is required" : ''}
                fullWidth
              />
            )}
          </Grid>
        );
      })}
    </>
  );
};

export default MerchantFields;
