import React, { useState, useContext } from "react";
import {
  Button,
  Grid,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  useTheme,
  useMediaQuery,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import AuthContext from "../../context/AuthContext";
import { fetchData } from "../../services/apiService";

const ClearingSettlementReportForm = ({
  setSnackOpen,
  setMessageOpen,
  setSeverity,
}) => {
  const { token, acquirerData } = useContext(AuthContext);
  const [currency, setCurrency] = useState(acquirerData?.currency_code || "");
  const [reportDate, setReportDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [hide, setHide] = useState(true);
  const [reportData, setReportData] = useState([]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleDateChange = (event) => {
    setReportDate(event.target.value);
  };

  const handleEndDateChange = (event) => {
    setEndDate(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const timestamp = new Date().toISOString();
    const data = {
      version: "04.09.01",
      acquirer: {
        callbackURL: "https://webhook.site/dd4eadec-dab5-4397-9754-c45738f2fb9e",
        timeStamp: timestamp,
      },
      fiat: {
        currencyCode: currency,
      },
      report: {
        startDate: reportDate.replaceAll("-", ""),
        endDate: endDate.replaceAll("-", ""),
      },
    };

    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      acquirerName: acquirerData.acquirerName,
      acquirerID: acquirerData.acquirerId,
    };

    try {
      const response = await fetchData({
        endpoint: "/periodic_daily-clearing-settlement",
        data,
        headers,
        token,
      });
      if (response && response.data.reports) {
        setReportData(response.data.reports);
        setHide(false);
        setSnackOpen(true);
        setMessageOpen("Report generated successfully!");
        setSeverity("success");
      }
    } catch (error) {
      setSnackOpen(true);
      setMessageOpen("No transaction for this date");
      setSeverity("error");
    }
  };

  const downloadCSV = () => {
    const headers = [
      "Date",
      "Transactions Amount",
      "Transactions Count",
      "Amount Due",
      "Holding Name",
      "Holding ID",
      "Holding Transactions Amount",
      "Holding Transactions Count",
      "Holding Amount Due",
      "Group Name",
      "Group ID",
      "Group Transactions Amount",
      "Group Transactions Count",
      "Group Amount Due",
      "Group Tx MDR",
      "Group Tx Processing",
      "Merchant Name",
      "Merchant ID",
      "Merchant Amount Due",
      "Merchant Exchange Commission",
      "Merchant Fiat Conversion",
      "Merchant Transactions Amount",
      "Merchant Transactions Count",
      "Merchant Tx Processing",
      "Terminal ID", 
      "Terminal Exchange",
      "Terminal Amount Due",
      "Terminal Exchange Commission",
      "Terminal Fiat Conversion",
      "Terminal Transactions Amount",
      "Terminal Transactions Count",
      "Terminal Tx Processing"
    ];
  
    const formatDecimal = (value) => {

      if (typeof value === "string") {
        const cleanedValue = value.replace(",", ".").replace(/[^0-9.]/g, "");
    
        if ((cleanedValue.match(/\./g) || []).length > 1) {
          return "0.0000";
        }
    
        const number = parseFloat(cleanedValue);
  
        if (!isNaN(number)) {
          return number.toFixed(4);
        }
      }
    
      if (typeof value === "number") {
        return value.toFixed(4);
      }
    
      return "0.0000";
    };
    const rows = [];
  
    reportData.report.forEach((report) => {

      const date = `${report.date.toString().substring(4, 6)}/${report.date.toString().substring(6, 8)}/${report.date.toString().substring(0, 4)}`;

      report.holdings.holding.forEach((holding) => {
        holding.groups.group.forEach((group) => {
          group.merchants.merchant.forEach((merchant) => {
            if (merchant.terminals && merchant.terminals.terminal.length > 0) {
              merchant.terminals.terminal.forEach((terminal) => {
                rows.push([
                  date, 
                  formatDecimal(reportData.acquirer.transactionsAmount),
                  reportData.acquirer.transactionsCount,
                  formatDecimal(reportData.acquirer.amountDue),
                  holding.merchantHolding,
                  holding.merchantHoldingID,
                  formatDecimal(holding.transactionsAmount),
                  holding.transactionsCount,
                  formatDecimal(holding.amountDue),
                  group.merchantGroup,
                  group.merchantGroupID,
                  formatDecimal(group.transactionsAmount),
                  group.transactionsCount,
                  formatDecimal(group.amountDue),
                  formatDecimal(group.txMDR || "0.000"), 
                  formatDecimal(group.txProcessing || "0.000"),
                  merchant.name,
                  merchant.ID,
                  formatDecimal(merchant.amountDue),
                  formatDecimal(merchant.exchangeCommission),
                  formatDecimal(merchant.fiatConversion),
                  formatDecimal(merchant.transactionsAmount),
                  merchant.transactionsCount,
                  formatDecimal(merchant.txProcessing),
                  terminal.terminalID, 
                  terminal.exchangeID, 
                  formatDecimal(terminal.amountDue),
                  formatDecimal(terminal.exchangeCommission),
                  formatDecimal(terminal.fiatConversion),
                  formatDecimal(terminal.transactionsAmount),
                  terminal.transactionsCount,
                  formatDecimal(terminal.txProcessing)
                ]);
              });
            } else {
              rows.push([
                date, 
                formatDecimal(reportData.acquirer.transactionsAmount),
                reportData.acquirer.transactionsCount,
                formatDecimal(reportData.acquirer.amountDue),
                holding.merchantHolding,
                holding.merchantHoldingID,
                formatDecimal(holding.transactionsAmount),
                holding.transactionsCount,
                formatDecimal(holding.amountDue),
                group.merchantGroup,
                group.merchantGroupID,
                formatDecimal(group.transactionsAmount),
                group.transactionsCount,
                formatDecimal(group.amountDue),
                formatDecimal(group.txMDR || "0.000"), 
                formatDecimal(group.txProcessing || "0.000"),
                merchant.name,
                merchant.ID,
                formatDecimal(merchant.amountDue),
                formatDecimal(merchant.exchangeCommission),
                formatDecimal(merchant.fiatConversion),
                formatDecimal(merchant.transactionsAmount),
                merchant.transactionsCount,
                formatDecimal(merchant.txProcessing),
                "", 
                "", 
                "", 
                "", 
                "", 
                "", 
                ""  
              ]);
            }
          });
        });
      });
    });
  
    const csvContent = [headers, ...rows]
      .map((row) => row.map((cell) => `"${cell}"`).join(","))
      .join("\n");
  
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "ClearingSettlementReport.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  
  const renderHoldingsTable = (reportData) => {
    return (
    <Accordion style={{ marginTop: "20px" }}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="subtitle1">Report Details</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography variant="h6">Acquirer Details</Typography>
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Transactions Count</TableCell>
                <TableCell>Transactions Amount</TableCell>
                <TableCell>Exchange Commission</TableCell>
                <TableCell>Fiat Conversion</TableCell>
                <TableCell>TX MDR</TableCell>
                <TableCell>Amount Due</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>{reportData.acquirer.transactionsCount}</TableCell>
                <TableCell>{reportData.acquirer.transactionsAmount}</TableCell>
                <TableCell>{reportData.acquirer.exchangeCommission}</TableCell>
                <TableCell>{reportData.acquirer.fiatConversion}</TableCell>
                <TableCell>{reportData.acquirer.txProcessing}</TableCell>
                <TableCell style={{ color: 'green' }}>{reportData.acquirer.amountDue}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
  
        {reportData.report.map((item, index) => (
          <Accordion key={index}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>
                Report Date: {`${item.date.toString().replace(/^(\d{4})(\d{2})(\d{2})$/, "$2/$3/$1")}`}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              {item.holdings.holding.map((holding, holdingIndex) => (
                <Accordion key={holdingIndex}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>{holding.merchantHolding} - {holding.merchantHoldingID}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <TableContainer component={Paper}>
                      <Table size="small">
                        <TableHead>
                          <TableRow>
                            <TableCell>Transactions Count</TableCell>
                            <TableCell>Transactions Amount</TableCell>
                            <TableCell>Exchange Commission</TableCell>
                            <TableCell>Fiat Conversion</TableCell>
                            <TableCell>TX MDR</TableCell>
                            <TableCell>Amount Due</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow>
                            <TableCell>{holding.transactionsCount}</TableCell>
                            <TableCell>{holding.transactionsAmount}</TableCell>
                            <TableCell>{holding.exchangeCommission}</TableCell>
                            <TableCell>{holding.fiatConversion}</TableCell>
                            <TableCell>{holding.txProcessing}</TableCell>
                            <TableCell style={{ color: 'green' }}>{holding.amountDue}</TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
  
                    <Typography variant="h6" style={{ marginTop: "20px" }}>Groups</Typography>
                    {holding.groups.group.map((group, groupIndex) => (
                      <Accordion key={groupIndex}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                          <Typography>{group.merchantGroup} - {group.merchantGroupID}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <TableContainer component={Paper}>
                            <Table size="small">
                              <TableHead>
                                <TableRow>
                                  <TableCell>Transactions Count</TableCell>
                                  <TableCell>Transactions Amount</TableCell>
                                  <TableCell>Exchange Commission</TableCell>
                                  <TableCell>Fiat Conversion</TableCell>
                                  <TableCell>TX MDR</TableCell>
                                  <TableCell>Amount Due</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                <TableRow>
                                  <TableCell>{group.transactionsCount}</TableCell>
                                  <TableCell>{group.transactionsAmount}</TableCell>
                                  <TableCell>{group.exchangeCommission}</TableCell>
                                  <TableCell>{group.fiatConversion}</TableCell>
                                  <TableCell>{group.txProcessing}</TableCell>
                                  <TableCell style={{ color: 'green' }}>{group.amountDue}</TableCell>
                                </TableRow>
                              </TableBody>
                            </Table>
                          </TableContainer>
  
                          <Typography variant="h6" style={{ marginTop: "20px" }}>Merchants</Typography>
                          {group.merchants.merchant.map((merchant, merchantIndex) => (
                            <Accordion key={merchantIndex}>
                              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography>{merchant.name} - {merchant.ID}</Typography>
                              </AccordionSummary>
                              <AccordionDetails>
                                <TableContainer component={Paper}>
                                  <Table size="small">
                                    <TableHead>
                                      <TableRow>
                                        <TableCell>Transactions Count</TableCell>
                                        <TableCell>Transactions Amount</TableCell>
                                        <TableCell>Exchange Commission</TableCell>
                                        <TableCell>Fiat Conversion</TableCell>
                                        <TableCell>TX MDR</TableCell>
                                        <TableCell>Amount Due</TableCell>
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      <TableRow>
                                        <TableCell>{merchant.transactionsCount}</TableCell>
                                        <TableCell>{merchant.transactionsAmount}</TableCell>
                                        <TableCell>{merchant.exchangeCommission}</TableCell>
                                        <TableCell>{merchant.fiatConversion}</TableCell>
                                        <TableCell>{merchant.txProcessing}</TableCell>
                                        <TableCell style={{ color: 'green' }}>{merchant.amountDue}</TableCell>
                                      </TableRow>
                                    </TableBody>
                                  </Table>
                                </TableContainer>
  
                                <Typography variant="h6" style={{ marginTop: "20px" }}>Terminals</Typography>
                                {merchant.terminals && merchant.terminals.terminal.length > 0 ? (
                                  <TableContainer component={Paper}>
                                    <Table size="small">
                                      <TableHead>
                                        <TableRow>
                                          <TableCell>Terminal ID</TableCell>
                                          <TableCell>Exchange</TableCell>
                                          <TableCell>Transactions Count</TableCell>
                                          <TableCell>Transactions Amount</TableCell>
                                          <TableCell>Exchange Commission</TableCell>
                                          <TableCell>Fiat Conversion</TableCell>
                                          <TableCell>TX MDR</TableCell>
                                          <TableCell>Amount Due</TableCell>
                                        </TableRow>
                                      </TableHead>
                                      <TableBody>
                                        {merchant.terminals.terminal.map((terminal, terminalIndex) => (
                                          <TableRow key={terminalIndex}>
                                            <TableCell>{terminal.terminalID}</TableCell>
                                            <TableCell>{terminal.exchangeID}</TableCell>
                                            <TableCell>{terminal.transactionsCount}</TableCell>
                                            <TableCell>{terminal.transactionsAmount}</TableCell>
                                            <TableCell>{terminal.exchangeCommission}</TableCell>
                                            <TableCell>{terminal.fiatConversion}</TableCell>
                                            <TableCell>{terminal.txProcessing}</TableCell>
                                            <TableCell style={{ color: 'green' }}>{terminal.amountDue}</TableCell>
                                          </TableRow>
                                        ))}
                                      </TableBody>
                                    </Table>
                                  </TableContainer>
                                ) : (
                                  <Typography variant="body2" style={{ marginTop: "10px" }}>
                                    No terminals available.
                                  </Typography>
                                )}
                              </AccordionDetails>
                            </Accordion>
                          ))}
                        </AccordionDetails>
                      </Accordion>
                    ))}
                  </AccordionDetails>
                </Accordion>
              ))}
            </AccordionDetails>
          </Accordion>
        ))}
      </AccordionDetails>
    </Accordion>
  )};

  return (
    <>
      <form onSubmit={handleSubmit} style={{ margin: "20px 0" }}>
        <Grid container spacing={3}>        
          <Grid item xs={12} sm={4}>
            <FormControl fullWidth disabled> 
              <InputLabel id="currency-label">Currency Code</InputLabel>
              <Select
                labelId="currency-label"
                id="currency"
                value={currency || ""}
                label="Currency Code"
                onChange={(event) => setCurrency(event.target.value)}
                required
              >
                <MenuItem value="USD">USD</MenuItem>
                <MenuItem value="BRL">BRL</MenuItem>
                <MenuItem value="MXN">MXN</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              id="start-date"
              label="Start Date"
              type="date"
              value={reportDate}
              onChange={handleDateChange}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                min: new Date(new Date().setDate(new Date().getDate() - 90))
                  .toISOString()
                  .split("T")[0], 
                max: new Date(new Date().setDate(new Date().getDate() - 1))
                  .toISOString()
                  .split("T")[0], 
              }}
              required
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              id="end-date"
              label="End Date"
              type="date"
              value={endDate}
              onChange={handleEndDateChange}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                min: new Date(new Date().setDate(new Date().getDate() - 90))
                  .toISOString()
                  .split("T")[0], 
                max: new Date(new Date().setDate(new Date().getDate() - 1))
                  .toISOString()
                  .split("T")[0], 
              }}
              required
            />
          </Grid>
          <Grid item xs={12} textAlign={isMobile ? "center" : "left"} style={{ marginTop: "20px" }}>
            <Button variant="contained" type="submit" size="large">
              GENERATE
            </Button>
            {reportData && (
              <Button
                variant="outlined"
                style={{ marginLeft: "10px" }}
                onClick={downloadCSV}
                disabled={hide}
              >
                Download CSV
              </Button>
            )}
          </Grid>
        </Grid>
      </form>
      {!hide && reportData && renderHoldingsTable(reportData)}
    </>
  );
};

export default ClearingSettlementReportForm;
