export const getKeycloakToken = async () => {
  try {
    const url = `${process.env.REACT_APP_KEYCLOAK_URL}/realms/${process.env.REACT_APP_KEYCLOAK_REALM}/protocol/openid-connect/token`;
    
    const body = new URLSearchParams({
      client_id: process.env.REACT_APP_KEYCLOAK_CREATE_USER_CLIENT,
      client_secret: process.env.REACT_APP_KEYCLOAK_CREATE_USER_CLIENT_SECRET,
      grant_type: "client_credentials",
      scope: "email"
    });
    
    const response = await fetch(url, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body,
      credentials: "include",
    });
    
    if (!response.ok) {
      throw new Error(`Error getting token: ${response.statusText}`);
    }
    
    const data = await response.json();
    return data.access_token;
  } catch (error) {
    throw new Error("Error fetching Keycloak token: " + error.message);
  }
};

export const createUserInKeycloak = async (userData) => {
  try {
    const token = await getKeycloakToken();
    const baseUrl = `${process.env.REACT_APP_KEYCLOAK_URL}/admin/realms/${process.env.REACT_APP_KEYCLOAK_REALM}`;
    
    // create user
    const createUserResponse = await fetch(`${baseUrl}/users`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(userData),
    });
    
    if (!createUserResponse.ok) {
      throw new Error(`Error creating user: ${createUserResponse.statusText}`);
    }
    
    // Get created user ID
    const getUserResponse = await fetch(`${baseUrl}/users?username=${userData.username}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
    
    if (!getUserResponse.ok) {
      throw new Error(`Error fetching user ID: ${getUserResponse.statusText}`);
    }
    
    const users = await getUserResponse.json();
    if (!users.length) {
      throw new Error("User not found after creation");
    }
    
    const userId = users[0].id;
    
    // Send password update email
    const sendEmailResponse = await fetch(`${baseUrl}/users/${userId}/execute-actions-email`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(["UPDATE_PASSWORD"]),
    });
    
    if (!sendEmailResponse.ok) {
      throw new Error(`Error sending update password email: ${sendEmailResponse.statusText}`);
    }
    
    return { success: true, message: "User created and password update email sent successfully." };
  } catch (error) {
    throw new Error("Error in Keycloak user creation process: " + error.message);
  }
};

export const checkUserExists = async ({ username, email }) => {
  try {

    const token = await getKeycloakToken();
    const baseUrl = `${process.env.REACT_APP_KEYCLOAK_URL}/admin/realms/${process.env.REACT_APP_KEYCLOAK_REALM}/users`;

    let userExists = false;

    // Check by username
    if (username) {
      const responseUsername = await fetch(`${baseUrl}?username=${encodeURIComponent(username)}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      if (!responseUsername.ok) {
        throw new Error(`Error checking username existence: ${responseUsername.statusText}`);
      }

      const usersByUsername = await responseUsername.json();
      if (usersByUsername.length > 0) {
        userExists = true;
      }
    }

    // Check by email
    if (email) {
      const responseEmail = await fetch(`${baseUrl}?email=${encodeURIComponent(email)}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      if (!responseEmail.ok) {
        throw new Error(`Error checking email existence: ${responseEmail.statusText}`);
      }

      const usersByEmail = await responseEmail.json();
      if (usersByEmail.length > 0) {
        userExists = true;
      }
    }

    return userExists ? true : null;
  } catch (error) {
    throw new Error("Error checking Keycloak user: " + error.message);
  }
};

