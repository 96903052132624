import React, { useState, useContext, useEffect } from "react";
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  IconButton,
  useMediaQuery,
  useTheme,
  Chip,
  Box,
  Stepper,
  Step,
  StepLabel,
  CircularProgress,
  Backdrop
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from "@mui/icons-material/Delete";
import AuthContext from "../../context/AuthContext";
import { enrollmentTerminal } from "../../services/terminal/enrollmentTerminalService";
import { processFile, sendData } from "../../utils/excelValidatorAndSend"; 

import AcquirerFields, { acquirer_fields } from "./enrollment/AcquirerFields";
import MerchantFields, { merchant_fields } from "./enrollment/MerchantFields";
import BankFields, { bank_fields } from "./enrollment/BankFields";
import OwnersFields, { fields_owners } from "./enrollment/OwnersFields";
import ResultModal from "./enrollment/ResultModal";
import { initialFormData } from "./enrollment/formData";
import { createUserInKeycloak, checkUserExists } from "../../services/userService";

const steps = ["Merchant Info", "Merchant Commissions", "Bank Info", "Owners Info", "Terminals"];

const FormComponent = ({ setSnackOpen, setMessageOpen, setSeverity }) => {
  const { userInfo, token, acquirerData } = useContext(AuthContext);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [formData, setFormData] = useState(initialFormData);
  const [file, setFile] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [successfulData, setSuccessfulData] = useState([]);
  const [failedData, setFailedData] = useState([]);

  const [activeStep, setActiveStep] = useState(0);

  const [filteredSteps, setFilteredSteps] = useState(steps);
  const [loading, setLoading] = useState(false);
  const [role, setRole] = useState(null);
  const [submittedSteps, setSubmittedSteps] = useState(new Set());
  const [invalidFields, setInvalidFields] = useState([]);
  useEffect(() => {
    if (userInfo) {
      setRole(userInfo["acquirer-rol-data"]);
    }
  }, [userInfo]);
  
  useEffect(() => {
    const newFilteredSteps = formData.acquirer.iso === "True"
      ? ["Merchant Info", "Merchant Commissions", "Bank Info", "Owners Info", "Terminals"]
      : ["Merchant Info", "Terminals"];
  
    setFilteredSteps(newFilteredSteps);
    setActiveStep(0); 
  }, [formData.acquirer.iso]);
  
  const filteredStep = ["acquirer", "merchant", "bank", "owner", "terminals"]; 

  const getFieldsForStep = (step) => {
    switch (step) {
      case "acquirer":
        return acquirer_fields;
      case "merchant":
        return merchant_fields;
      case "bank":
        return bank_fields;
      case "owner":
        return fields_owners;
      default:
        return [];
    }
  };

  const validateStep = (step) => {
    const currentStepFields = getFieldsForStep(step);
    const invalidFields = [];
  
    for (let field of currentStepFields) {
      if (step === 'owner') {
        const owners = formData[step];
        if (Array.isArray(owners) && owners.length > 0) {
          for (let owner of owners) {
            for (let ownerField of currentStepFields) {
              if (!owner[ownerField.name]) {
                invalidFields.push(`${step}.${ownerField.name}`);
              }
            }
          }
        } else {
          return { isValid: false, invalidFields: ['owner'] };
        }
      } else if (typeof field.required === 'function') {
        const isRequired = field.required(formData);
        if (isRequired && !formData[step]?.[field.name]) {
          invalidFields.push(`${step}.${field.name}`);
        }
      } else if (field.required && !formData[step]?.[field.name]) {
        invalidFields.push(`${step}.${field.name}`);
      }
    }
  
    return { 
      isValid: invalidFields.length === 0,
      invalidFields 
    };
  };  
  
  const handleNext = () => {
    const currentStep = filteredStep[activeStep];
    const validation = validateStep(currentStep);
    
    if (!validation.isValid) {
      let errorMessage = "Please fill in all required fields!";
      setSeverity("error");
      setMessageOpen(errorMessage);
      setSnackOpen(true);
      setInvalidFields(validation.invalidFields);
      setSubmittedSteps((prev) => new Set(prev).add(currentStep));
      return;
    }
  
    setInvalidFields([]);
    setActiveStep((prevStep) => {
      if (activeStep < filteredStep.length - 1) {
        return prevStep + 1;
      }
      return prevStep;
    });
  };

  
  const hasAccess = (allowedRoles) => allowedRoles.includes(role);
  const handleBack = () => {
    setActiveStep((prevStep) => {
      if (activeStep > 0) {
        return prevStep - 1;
      }
      return prevStep;
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      acquirer: {
        ...prevState.acquirer,
        [name]: value,
      },
    }));
  };

  const handleInputChangeMerchant = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      merchant: {
        ...prevState.merchant,
        [name]: value,
      },
    }));
  };
  
  const handleInputChangeBank = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      bank: {
        ...prevState.bank,
        [name]: value,
      },
    }));
  };

  const handleTerminalChange = (index, field, value) => {
    const updatedTerminals = [...formData.acquirer.terminal.terminals];
    updatedTerminals[index][field] = value;
    setFormData((prev) => ({
      ...prev,
      acquirer: {
        ...prev.acquirer,
        terminal: {
          ...prev.acquirer.terminal,
          terminals: updatedTerminals,
        },
      },
    }));
  };

  const handleAddTerminal = () => {
    setFormData((prev) => ({
      ...prev,
      acquirer: {
        ...prev.acquirer,
        terminal: {
          ...prev.acquirer.terminal,
          terminals: [
            ...prev.acquirer.terminal.terminals,
            { terminalID: "", terminalType: "", terminalSerial: "" },
          ],
        },
      },
    }));
  };

  const handleDeleteTerminal = (index) => {
    setFormData((prev) => ({
      ...prev,
      acquirer: {
        ...prev.acquirer,
        terminal: {
          ...prev.acquirer.terminal,
          terminals: prev.acquirer.terminal.terminals.filter((_, i) => i !== index),
        },
      },
    }));
  };

  const handleAddOwner = () => {
    setFormData((prev) => ({
      ...prev,
      owner: [...prev.owner, 
        fields_owners.reduce((acc, field) => {
          acc[field.name] = "";
          return acc;
        }, {})
      ],
    }));
  };

  const handleOwnerChange = (index, field, value) => {
    const updatedOwners = [...formData.owner];
    updatedOwners[index][field] = value;
    setFormData((prev) => ({
      ...prev,
      owner: updatedOwners,
    }));
  };

  const handleDeleteOwner = (index) => {
    setFormData((prev) => ({
      ...prev,
      owner: prev.owner.filter((_, i) => i !== index),
    }));
  };

  const handleFileChange = (e) => {
    const uploadedFile = e.target.files[0];
    if (uploadedFile) {
      setFile(uploadedFile);
    }
  };

  const processFileUpload = () => {
    const url = "/enrollment";
  
    if (file) {

      setLoading(true);
      processFile(file, acquirerData)

        .then(({ filteredCellData }) => {
          if (!filteredCellData || filteredCellData.length === 0) {
            throw new Error("Processed file is empty or invalid.");
          }
  
          return sendData(filteredCellData, url, token, acquirerData);
        })
        .then(({ successfulData, failedData }) => {
          setSuccessfulData(successfulData);
          setFailedData(failedData);
          setIsModalOpen(true);
        })
        .catch((error) => {
        console.error("Error:", error);
        setFailedData(error.message);
        setIsModalOpen(true);
        })
        .finally(() => {
          setLoading(false);

        });
    } else {
      console.error("No files selected for upload.");
      setFailedData("No files selected for upload.");
      setIsModalOpen(true);
    }
  };
  
  

  const handleSubmit = async (e) => {
    if (formData.acquirer.terminal.terminals.some(terminal => 
      !terminal.terminalID || !terminal.terminalType || !terminal.terminalSerial)) {
      let errorMessage = "Please fill in all terminal information before submitting!";
      setSeverity("error");
      setMessageOpen(errorMessage); 
      setSnackOpen(true); 
      return;
    }
  
    try {
      setLoading(true);
      const url = "/enrollment";
      const email = formData.acquirer.email;
      const sanitizedUsername = formData.acquirer.name
        .toLowerCase()
        .replace(/\s+/g, "_")
        .replace(/[^a-z0-9_]/g, "");
      
      // Check if user already exists
      const existingUser = await checkUserExists({ username: sanitizedUsername, email });
      if (existingUser) {
        setSeverity("error");
        setMessageOpen("User already exists with this email or Merchant Name!");
        setSnackOpen(true);
        return;
      }
  
      // create merchant terminal
      const response = await enrollmentTerminal(url, formData, token, acquirerData);
      let successMessage = `Merchant Terminal successfully saved!`;
  
      if (response.data) {
        // create user
        const rol_data = formData.acquirer.iso === true || formData.acquirer.iso === "True" ? "ISO" : "merchant";
        const userData = {
          username: sanitizedUsername,
          email: email,
          emailVerified: false,
          enabled: true,
          credentials: [
            {
              type: "password",
              value: "123456",
              temporary: true,
            },
          ],
          attributes: {
            "acquirer-rol-data": rol_data,
            "acquirer-id-data": acquirerData.acquirerId,
            "acquirer-name-data": acquirerData.acquirerName,
            "acquirer-currency-data": formData.acquirer.currency,
            ...(rol_data === "ISO" ? { "acquirer-id-iso-data": formData.acquirer.isoID } : {}),
          },
        };
  
        try {
          await createUserInKeycloak(userData);
          successMessage += `\n👤 User successfully created! Check your email to log in!`;
        } catch (keycloakError) {
          console.error("Error creating user in Keycloak:", keycloakError);
          setSeverity("error");
          setMessageOpen(`ISO created, ISO ID: ${response.data.isoID}, but user not created because there is already a user with that email or name!`);
          setSnackOpen(true);
          resetForm();
          return; 
        }
      }
  
      setSnackOpen(response.data?.holdings?.holding.length > 0 ? false : true);
      setMessageOpen(successMessage);
      setSeverity("success");
      resetForm();
    } catch (error) {
      let errorMessage = "Merchant Terminal NOT saved!";
      if (error.request?.responseText) {
        try {
          const response = JSON.parse(error.request.responseText); 
          if (response.detail) {
            errorMessage += `: ${response.detail}`; 
          }
        } catch (parseError) {
          console.error("Failed to parse error.responseText:", parseError);
          errorMessage += `: ${error.request.responseText}`;
        }
      }
      setSeverity("error");
      setMessageOpen(errorMessage); 
      setSnackOpen(true); 
    } finally {
      setLoading(false);
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  
  const resetForm = () => {
    setFormData({
      ...initialFormData,
      owner: [{ 
        ...fields_owners.reduce((acc, field) => {
          acc[field.name] = "";
          return acc;
        }, {})
      }],
      acquirer: {
        ...initialFormData.acquirer,
        terminal: {
          ...initialFormData.acquirer.terminal,
          terminals: [{
            terminalID: "",
            terminalType: "",
            terminalSerial: ""
          }]
        }
      }
    });
    
    setInvalidFields([]);
    setSubmittedSteps(new Set());
    setActiveStep(0);
    setFile(null);
    setSuccessfulData([]);
    setFailedData([]);
  };


  const handleStepClick = (index) => {

    if (index < activeStep) {
      setActiveStep(index);
      return;
    }
  
    const currentStep = filteredStep[activeStep];
    const validation = validateStep(currentStep);
  
    if (!validation.isValid) {
      let errorMessage = "Please fill in all required fields!";
      setSeverity("error");
      setMessageOpen(errorMessage);
      setSnackOpen(true);
      setInvalidFields(validation.invalidFields);
      setSubmittedSteps((prev) => new Set(prev).add(currentStep));
      return;
    }
  
    setInvalidFields([]);
    setActiveStep(index);
  };

  return (
    <Box sx={{ width: "100%", py: 4 }}>

      <Backdrop open={loading} sx={{ color: "#fff", zIndex: 1301 }}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <Stepper
        activeStep={activeStep}
        alternativeLabel
        sx={{ mb: 3, padding: 2, backgroundColor: '#f5f5f5', borderRadius: 2 }}
      >
        {filteredSteps.map((label, index) => (
          <Step key={index}>
            <StepLabel
              sx={{
                color: activeStep === index ? 'primary.main' : 'text.secondary',
                fontWeight: activeStep === index ? 'bold' : 'normal',
              }}
              onClick={() => handleStepClick(index)} 
            >
              {label}
            </StepLabel>
          </Step>
        ))}
      </Stepper>

      <form onSubmit={handleSubmit}>
        <ResultModal
          open={isModalOpen}
          onClose={handleCloseModal}
          successfulData={successfulData}
          failedData={failedData}
        />

        <Grid container spacing={isMobile ? 2 : 4} pb={isMobile ? "16px" : "32px"}>
          {activeStep === filteredSteps.indexOf("Merchant Info") && 
            <AcquirerFields 
              formData={formData} 
              handleInputChange={handleInputChange} 
              acquirerData={acquirerData} 
              submittedSteps={submittedSteps} 
              invalidFields={invalidFields}
            />
          }
          {activeStep === filteredSteps.indexOf("Merchant Commissions") && (
            <MerchantFields 
              formData={formData} 
              handleInputChange={handleInputChangeMerchant}
              submittedSteps={submittedSteps}
              invalidFields={invalidFields}
            />
          )}
          {activeStep === filteredSteps.indexOf("Bank Info") && 
            <BankFields 
              formData={formData} 
              handleInputChange={handleInputChangeBank} 
              submittedSteps={submittedSteps}
              invalidFields={invalidFields}
            />
          }
          {activeStep === filteredSteps.indexOf("Owners Info") && (
            <>
              <Grid item xs={12} sx={{ mt: 4, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Box>
                  <Chip label={`${formData.owner.length} Owner(s)`} color="info" size="small" />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <OwnersFields 
                  formData={formData} 
                  handleOwnerChange={handleOwnerChange} 
                  handleDeleteOwner={handleDeleteOwner} 
                  submittedSteps={submittedSteps}
                  invalidFields={invalidFields}
                />
              </Grid>
              <Grid item xs={12} sx={{ mt: 4, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Box display="flex" alignItems="center">
                  <Tooltip title="Add New Owner">
                    <Button variant="contained" component="span" onClick={handleAddOwner}>
                      Add Owner
                    </Button>
                  </Tooltip>
                </Box>
              </Grid>
            </>
          )}
          {activeStep === filteredSteps.indexOf("Terminals") && (
            <>
              {formData.acquirer.terminal.terminals.map((terminal, index) => (
                <Grid item xs={10} key={index}>
                  <Grid container spacing={3}>
                    <Grid item xs={3}>
                      <TextField
                        label={
                          <>
                            Terminal ID
                            <Tooltip title={"Terminal ID"} arrow>
                              <IconButton size="small" sx={{ padding: 0, marginLeft: '4px' }}>
                                <InfoIcon color="primary" fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          </>
                        }
                        value={terminal.terminalID}
                        onChange={(e) => handleTerminalChange(index, "terminalID", e.target.value)}
                        fullWidth
                        required
                        inputProps={{ maxLength: 16 }}
                        InputLabelProps={{ 
                          sx: {
                            '& .MuiFormLabel-asterisk': {
                              color: 'red',
                              fontSize: '1.2rem',
                            }
                          }
                        }}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <FormControl fullWidth>
                        <InputLabel 
                          id="terminal-type-label"
                          required
                          sx={{
                            '& .MuiFormLabel-asterisk': {
                              color: 'red',
                              fontSize: '1.2rem',
                            }
                          }}
                        >
                          Terminal Type
                          <Tooltip title={'Terminal Type'} arrow>
                            <IconButton size="small" sx={{ padding: 0, marginLeft: '4px' }}>
                              <InfoIcon color="primary" fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        </InputLabel>
                        <Select
                          labelId="terminal-type-label"
                          value={terminal.terminalType}
                          label="Terminal Type"
                          onChange={(e) => handleTerminalChange(index, "terminalType", e.target.value)}
                          required
                        >
                          <MenuItem value="A">ATM = A</MenuItem>
                          <MenuItem value="C">COTS (Phone/Tablet) = C</MenuItem>
                          <MenuItem value="E">eCommerce = E</MenuItem>
                          <MenuItem value="R">ECR = R</MenuItem>
                          <MenuItem value="I">App = I</MenuItem>
                          <MenuItem value="K">Kiosk = K</MenuItem>
                          <MenuItem value="M">mPOS = M</MenuItem>
                          <MenuItem value="P">POS = P</MenuItem>
                          <MenuItem value="T">Teller = T</MenuItem>
                          <MenuItem value="V">Vending Machine = V</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                      <TextField
                        label={
                          <>
                            Terminal Serial
                            <Tooltip title={"Terminal Serial"} arrow>
                              <IconButton size="small" sx={{ padding: 0, marginLeft: '4px' }}>
                                <InfoIcon color="primary" fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          </>
                        }
                        value={terminal.terminalSerial}
                        onChange={(e) => handleTerminalChange(index, "terminalSerial", e.target.value)}
                        fullWidth
                        required
                        inputProps={{ maxLength: 16 }}
                        InputLabelProps={{ 
                          sx: {
                            '& .MuiFormLabel-asterisk': {
                              color: 'red',
                              fontSize: '1.2rem',
                            }
                          }
                        }}
                      />
                    </Grid>
                    <Grid item xs={3} sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                      <IconButton
                        onClick={() => handleDeleteTerminal(index)}
                        disabled={formData.acquirer.terminal.terminals.length === 1}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Grid>
              ))}
              <Grid item xs={2} sx={{ alignSelf: "center" }}>
                <Button variant="contained" onClick={handleAddTerminal}>
                  Add Terminal
                </Button>
              </Grid>
            </>
          )}
        </Grid>

        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mt: 3 }}>
          <Box sx={{ display: "flex", gap: 2 }}>
            <Button disabled={activeStep === 0} onClick={handleBack}>
              Back
            </Button>
            {activeStep === filteredSteps.length - 1 ? (
              <Button variant="contained" color="primary" onClick={() => handleSubmit()}>
                Finish
              </Button>
            ) : (
              <Button variant="contained" onClick={handleNext}>
                Next
              </Button>
            )}
          </Box>
        </Box>


        <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 2, mt: 3 }}>
          <input
            accept=".xls, .xlsx"
            style={{ display: "none" }}
            id="contained-button-file"
            type="file"
            onChange={handleFileChange}
          />
          <label htmlFor="contained-button-file">
            <Button variant="contained" component="span">
              Upload Excel File
            </Button>
          </label>

          {file && (
            <Button variant="contained" color="success" onClick={processFileUpload}>
              Process Upload
            </Button>
          )}
          
          {hasAccess(["admin", "ISO"]) && (
            <a
              id="template"
              href={`${process.env.PUBLIC_URL}/Merchant & Terminal ISO.xlsx`}
              download
              style={{ textDecoration: "none" }}
            >
              <Button variant="outlined">Download Template ISO</Button>
            </a>
          )}

          {hasAccess(["admin", "merchant"]) && (
            <a
              id="template"
              href={`${process.env.PUBLIC_URL}/Merchant & Terminal.xlsx`}
              download
              style={{ textDecoration: "none" }}
            >
              <Button variant="outlined">Download Template</Button>
            </a>
          )}
        </Box>

      </form>
    </Box>
  );
};

export default FormComponent;
