import React, { useState, useContext } from "react";
import {
  Box,
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Pagination,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from "@mui/material";

import SaveIcon from '@mui/icons-material/Save';
import MenuItem from '@mui/material/MenuItem';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';

import AuthContext from "../../../context/AuthContext";
import { fetchData } from "../../../services/apiService";

export default function IsoSearch({ setSnackOpen, setMessageOpen, setSeverity }) {
  const { acquirerData, token } = useContext(AuthContext);
  const [iso, setIso] = useState([]);
  const [loadingISO, setLoadingISO] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [viewMode, setViewMode] = useState(true); 
  const [openModal, setOpenModal] = useState(false);
  const itemsPerPage = 10;

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    handleOpenModal();
  };
  
  const handleConfirmSave = () => {
    setOpenModal(false);
    handleSaveDetails(new Event('submit'));
  };  

  const [expandedRow, setExpandedRow] = useState(null);

  const handleSearchISO = async () => {
    setLoadingISO(true);
    setIso([]);

    try {
      const endpoint = "/list-iso";
      const timestamp = new Date().toISOString();
      const data = {
        version: "04.09.01",
        acquirerID: acquirerData.acquirerId,
        timeStamp: timestamp,
      };
      const headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        acquirerName: acquirerData.acquirerName,
        acquirerID: acquirerData.acquirerId,
      };

      const response = await fetchData({ endpoint, data, headers, token });
      setIso(response.data || []);

      if (response.data?.length) {
        setSnackOpen(true);
        setMessageOpen("ISO loaded successfully");
        setSeverity("success");
      } else {
        setSnackOpen(true);
        setMessageOpen("No ISO found");
        setSeverity("info");
      }
    } catch (error) {
      console.error(error);
      setSnackOpen(true);
      setMessageOpen("Error while fetching ISO");
      setSeverity("error");
    } finally {
      setLoadingISO(false);
    }
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const handleDetailChange = (field, value, section) => {
    const updatedIso = iso.map((item) =>
      item.isoID === expandedRow
        ? {
            ...item,
            ...(section ? {
              [section]: {
                ...item[section],
                [field]: value, 
              }
            } : {
              [field]: value, 
            })
          }
        : item
    );
    setIso(updatedIso);
  };

  const handleSaveDetails = async (event) => {
    event.preventDefault();
    if (!expandedRow) return;
  
    const updatedItem = iso.find((item) => item.isoID === expandedRow);

    if (!updatedItem) return;
    setLoadingISO(true);
  
    try {
      const endpoint = "/update-iso";
      const headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        acquirerName: acquirerData.acquirerName,
        acquirerID: acquirerData.acquirerId,
      };
  
      const timestamp = new Date().toISOString();
      
      const data = {
        version: "04.09.01",
        acquirerID: acquirerData.acquirerId,
        fiat: {
          currencyCode: updatedItem.isoCurrencyCode,
        },
        isoID: updatedItem.isoID,
        isoName: updatedItem.isoName,
        iso: updatedItem.iso,
        bank: updatedItem.bank,
        timeStamp: timestamp,
      };
  
      const response = await fetchData({ endpoint, data, headers, token });
      if (response.data) {
        setSnackOpen(true);
        setMessageOpen(`ISO ${updatedItem.isoID} updated successfully!`);
        setSeverity("success");
      } else {
        setSnackOpen(true);
        setMessageOpen("Failed to update ISO details");
        setSeverity("error");
      }
    } catch (error) {
      console.error(error);
      setSnackOpen(true);
      setMessageOpen("Error updating ISO details");
      setSeverity("error");
    } finally {
      setLoadingISO(false);
      setExpandedRow(null);
    }
  };  

  const filteredIso = iso.filter(
    (item) =>
      item.isoName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.isoID?.includes(searchTerm)
  );

  const displayedIso = filteredIso.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handleRowClick = (id, mode) => {
    setExpandedRow(expandedRow === id ? null : id);
    setViewMode(mode === 'view');
  };

  const ConfirmationModal = ({ open, onClose, onConfirm }) => {
    return (
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>Confirm Changes</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to save the changes?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="secondary" variant="outlined">
            Cancel
          </Button>
          <Button onClick={onConfirm} color="primary" variant="contained">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <Box>
      <ConfirmationModal 
        open={openModal} 
        onClose={handleCloseModal} 
        onConfirm={handleConfirmSave} 
      />

      <Grid container spacing={2} alignItems="center" mb={2}>
        <Grid item xs={12}>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            onClick={handleSearchISO}
            disabled={loadingISO}
          >
            {loadingISO ? "Loading ISO..." : "Load ISO"}
          </Button>
        </Grid>
        {iso.length > 0 && (
          <Grid item xs={12}>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Search ISO..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </Grid>
        )}
      </Grid>

      {iso.length > 0 && (
        <>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ISO ID</TableCell>
                  <TableCell>ISO Name</TableCell>
                  <TableCell>Currency</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {displayedIso.map((item, index) => (
                    <React.Fragment key={index}>
                      <TableRow>
                        <TableCell>{item.isoID}</TableCell>
                        <TableCell>{item.isoName}</TableCell>
                        <TableCell>{item.isoCurrencyCode}</TableCell>
                        <TableCell>
                          <Button
                            variant="outlined"
                            color="secondary" secondary
                            onClick={() => handleRowClick(item.isoID, 'view')}
                          >
                            <VisibilityIcon />
                          </Button>

                          <Button
                            variant="outlined"
                            color="primary"
                            onClick={() => handleRowClick(item.isoID, 'edit')}
                            sx={{ ml: 1 }}
                          >
                            <EditIcon />
                          </Button>
                        </TableCell>
                      </TableRow>
                      {expandedRow === item.isoID && (
                        <TableRow>
                          <TableCell colSpan={4}>
                            <Accordion expanded={expandedRow === item.isoID}>
                              <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
                                <Typography>Details</Typography>
                              </AccordionSummary>
                              <AccordionDetails>
                                <form onSubmit={handleSubmit} style={{ margin: "20px 0" }}>
                                  <Box display="flex" flexDirection="column" gap={2} sx={{ pb: 2 }}>
                                    {/* ISO */}
                                    <Typography variant="h6">ISO Details</Typography>
                                    <Grid container spacing={2}>
                                      <Grid item xs={4}>
                                        <TextField fullWidth label="ISO ID" value={item.isoID} disabled />
                                      </Grid>
                                      <Grid item xs={4}>
                                        <TextField
                                          fullWidth
                                          label="ISO Name"
                                          value={item.isoName}
                                          onChange={(e) => handleDetailChange("isoName", e.target.value)}
                                          required
                                          disabled={viewMode}
                                        />
                                      </Grid>
                                      <Grid item xs={4}>
                                        <TextField fullWidth label="Currency" value={item.isoCurrencyCode} disabled />
                                      </Grid>
                                      <Grid item xs={4}>
                                        <TextField
                                          fullWidth
                                          label="Address"
                                          value={item.iso?.address || ""}
                                          onChange={(e) => handleDetailChange("address", e.target.value, "iso")}
                                          required
                                          disabled={viewMode}
                                        />
                                      </Grid>
                                      <Grid item xs={4}>
                                        <TextField
                                          fullWidth
                                          label="City"
                                          value={item.iso?.city || ""}
                                          onChange={(e) => handleDetailChange("city", e.target.value, "iso")}
                                          required
                                          disabled={viewMode}
                                        />
                                      </Grid>
                                      <Grid item xs={4}>
                                        <TextField
                                          fullWidth
                                          label="State"
                                          value={item.iso?.state || ""}
                                          onChange={(e) => handleDetailChange("state", e.target.value, "iso")}
                                          required
                                          disabled={viewMode}
                                        />
                                      </Grid>
                                      <Grid item xs={4}>
                                        <TextField
                                          fullWidth
                                          label="Country Code"
                                          value={item.iso?.countryCode || ""}
                                          onChange={(e) => handleDetailChange("countryCode", e.target.value, "iso")}
                                          required
                                          disabled={viewMode}
                                        />
                                      </Grid>
                                      <Grid item xs={4}>
                                        <TextField
                                          fullWidth
                                          label="Postal Code"
                                          value={item.iso?.postalCode || ""}
                                          onChange={(e) => handleDetailChange("postalCode", e.target.value, "iso")}
                                          required
                                          disabled={viewMode}
                                        />
                                      </Grid>
                                      <Grid item xs={4}>
                                        <TextField
                                          fullWidth
                                          label="Neighborhood"
                                          value={item.iso?.neighborhood || ""}
                                          onChange={(e) => handleDetailChange("neighborhood", e.target.value, "iso")}
                                          disabled={viewMode}
                                        />
                                      </Grid>
                                      <Grid item xs={4}>
                                        <TextField
                                          fullWidth
                                          label="Effective Date"
                                          type="date"
                                          value={
                                            item.iso?.effectiveDate
                                              ? `${item.iso.effectiveDate.slice(0, 4)}-${item.iso.effectiveDate.slice(4, 6)}-${item.iso.effectiveDate.slice(6, 8)}`
                                              : ""
                                          }
                                          onChange={(e) => handleDetailChange("effectiveDate", e.target.value, "iso")}
                                          required
                                          InputLabelProps={{
                                            shrink: true,
                                          }}
                                          disabled={viewMode}
                                        />
                                      </Grid>
                                      <Grid item xs={4}>
                                        <TextField
                                          fullWidth
                                          label="Amount Terminal Maintenance"
                                          value={item.iso?.amountTerminalMaintenance || ""}
                                          onChange={(e) => handleDetailChange("amountTerminalMaintenance", e.target.value, "iso")}
                                          required
                                          disabled={viewMode}
                                        />
                                      </Grid>
                                      <Grid item xs={4}>
                                        <TextField
                                          fullWidth
                                          label="Amount Terminal Maintenance Tiers"
                                          value={item.iso?.amountTerminalMaintenanceTiers || ""}
                                          onChange={(e) => handleDetailChange("amountTerminalMaintenanceTiers", e.target.value, "iso")}
                                          disabled={viewMode}
                                        />
                                      </Grid>
                                      <Grid item xs={4}>
                                        <TextField
                                          fullWidth
                                          label="Amount Terminal Setup"
                                          value={item.iso?.amountTerminalSetup || ""}
                                          onChange={(e) => handleDetailChange("amountTerminalSetup", e.target.value, "iso")}
                                          required
                                          disabled={viewMode}
                                        />
                                      </Grid>
                                      <Grid item xs={4}>
                                        <TextField
                                          fullWidth
                                          label="Amount Terminal Setup Tiers"
                                          value={item.iso?.amountTerminalSetupTiers || ""}
                                          onChange={(e) => handleDetailChange("amountTerminalSetupTiers", e.target.value, "iso")}
                                          disabled={viewMode}
                                        />
                                      </Grid>
                                      <Grid item xs={4}>
                                        <TextField
                                          fullWidth
                                          label="Amount TX Processing"
                                          value={item.iso?.amountTxProcessing || ""}
                                          onChange={(e) => handleDetailChange("amountTxProcessing", e.target.value, "iso")}
                                          required
                                          disabled={viewMode}
                                        />
                                      </Grid>
                                      <Grid item xs={4}>
                                        <TextField
                                          fullWidth
                                          disabled={viewMode}
                                          label="Amount TX Processing Tiers"
                                          value={item.iso?.amountTxProcessing || ""}
                                          onChange={(e) => handleDetailChange("amountTxProcessingTiers", e.target.value, "iso")}
                                        />
                                      </Grid>
                                      <Grid item xs={4}>
                                        <TextField
                                          fullWidth
                                          disabled={viewMode}
                                          label="Percent TX Processing"
                                          value={item.iso?.percentTxProcessing || ""}
                                          onChange={(e) => handleDetailChange("percentTxProcessing", e.target.value, "iso")}
                                          required
                                        />
                                      </Grid>
                                      <Grid item xs={4}>
                                        <TextField
                                          fullWidth
                                          disabled={viewMode}
                                          label="Percent TX Processing Tiers"
                                          value={item.iso?.percentTxProcessingTiers || ""}
                                          onChange={(e) => handleDetailChange("percentTxProcessingTiers", e.target.value, "iso")}
                                        />
                                      </Grid>
                                      <Grid item xs={4}>
                                        <TextField
                                          select
                                          fullWidth
                                          disabled={viewMode}
                                          label="Terminal Maintenance Inactive"
                                          value={item.iso?.terminalMaintenanceInactive ? "true" : "false"}
                                          onChange={(e) => handleDetailChange("terminalMaintenanceInactive", e.target.value === "true", "iso")}
                                          required
                                        >
                                          <MenuItem value="true">True</MenuItem>
                                          <MenuItem value="false">False</MenuItem>
                                        </TextField>
                                      </Grid>
                                    </Grid>

                                    {/* Bank */}
                                    <Typography variant="h6" mt={4}>Bank Details</Typography>
                                    <Grid container spacing={2}>
                                      <Grid item xs={4}>
                                        <TextField
                                          fullWidth
                                          disabled={viewMode}
                                          label="Bank ID"
                                          value={item.bank?.Bid || ""}
                                          onChange={(e) => handleDetailChange("Bid", e.target.value, "bank")}
                                        />
                                      </Grid>
                                      <Grid item xs={4}>
                                        <TextField
                                          fullWidth
                                          disabled={viewMode}
                                          label="Account Number"
                                          value={item.bank?.accountNumber || ""}
                                          onChange={(e) => handleDetailChange("accountNumber", e.target.value, "bank")}
                                        />
                                      </Grid>
                                      <Grid item xs={4}>
                                        <TextField
                                          fullWidth
                                          disabled={viewMode}
                                          label="Routing Number"
                                          value={item.bank?.routingNumber || ""}
                                          onChange={(e) => handleDetailChange("routingNumber", e.target.value, "bank")}
                                        />
                                      </Grid>
                                      <Grid item xs={4}>
                                        <TextField
                                          fullWidth
                                          disabled={viewMode}
                                          label="Swift Code"
                                          value={item.bank?.swiftCode || ""}
                                          onChange={(e) => handleDetailChange("swiftCode", e.target.value, "bank")}
                                        />
                                      </Grid>
                                      <Grid item xs={4}>
                                        <TextField
                                          fullWidth
                                          disabled={viewMode}
                                          label="Branch Number"
                                          value={item.bank?.branchNumber || ""}
                                          onChange={(e) => handleDetailChange("branchNumber", e.target.value, "bank")}
                                        />
                                      </Grid>
                                      <Grid item xs={4}>
                                        <TextField
                                          fullWidth
                                          disabled={viewMode}
                                          label="IBAN"
                                          value={item.bank?.Iban || ""}
                                          onChange={(e) => handleDetailChange("Iban", e.target.value, "bank")}
                                        />
                                      </Grid>
                                      <Grid item xs={4}>
                                        <TextField
                                          fullWidth
                                          disabled={viewMode}
                                          label="Address"
                                          value={item.bank?.address || ""}
                                          onChange={(e) => handleDetailChange("address", e.target.value, "bank")}
                                        />
                                      </Grid>
                                      <Grid item xs={4}>
                                        <TextField
                                          fullWidth
                                          disabled={viewMode}
                                          label="City"
                                          value={item.bank?.city || ""}
                                          onChange={(e) => handleDetailChange("city", e.target.value, "bank")}
                                        />
                                      </Grid>
                                      <Grid item xs={4}>
                                        <TextField
                                          fullWidth
                                          disabled={viewMode}
                                          label="State"
                                          value={item.bank?.state || ""}
                                          onChange={(e) => handleDetailChange("state", e.target.value, "bank")}
                                        />
                                      </Grid>
                                      <Grid item xs={4}>
                                        <TextField
                                          fullWidth
                                          disabled={viewMode}
                                          label="Country Code"
                                          value={item.bank?.countryCode || ""}
                                          onChange={(e) => handleDetailChange("countryCode", e.target.value, "bank")}
                                        />
                                      </Grid>
                                      <Grid item xs={4}>
                                        <TextField
                                          fullWidth
                                          disabled={viewMode}
                                          label="Postal Code"
                                          value={item.bank?.postalCode || ""}
                                          onChange={(e) => handleDetailChange("postalCode", e.target.value, "bank")}
                                        />
                                      </Grid>
                                    </Grid>
                                  </Box>
                                  {!viewMode && (
                                    <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
                                      <Button variant="contained" size="large" type="submit">
                                        <SaveIcon />
                                      </Button>
                                    </Box>
                                  )}
                                </form>
                              </AccordionDetails>
                            </Accordion>
                          </TableCell>
                        </TableRow>
                    )}
                  </React.Fragment>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <Box display="flex" justifyContent="center" mt={2}>
            <Pagination
              count={Math.ceil(filteredIso.length / itemsPerPage)}
              page={currentPage}
              onChange={handlePageChange}
              color="primary"
            />
          </Box>
        </>
      )}
    </Box>
  );
}