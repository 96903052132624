import React, { useState, useContext } from "react";
import {
  Button,
  Grid,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  useTheme,
  useMediaQuery,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import AuthContext from "../../context/AuthContext";
import { fetchData } from "../../services/apiService";

const MerchantDailyReportForm = ({
  setSnackOpen,
  setMessageOpen,
  setSeverity,
}) => {
  const { token, acquirerData } = useContext(AuthContext);
  const [currency, setCurrency] = useState(acquirerData?.currency_code || "");
  const [holdingID, setHoldingID] = useState("");
  const [groupID, setGroupID] = useState("");
  const [merchantID, setMerchantID] = useState("");
  const [isoID, setIsoID] = useState(acquirerData?.isoID || "");
  const [reportDate, setReportDate] = useState("");
  const [hide, setHide] = useState(true);
  const [merchantData, setMerchantData] = useState([]);
  const [merchantHolding, setMerchantHolding] = useState("");
  const [merchantHoldingID, setMerchantHoldingID] = useState("");
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleDateChange = (event) => {
    setReportDate(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const timestamp = new Date().toISOString();
    const data = {
      version: "04.09.01",
      merchantHoldingID: holdingID !== "" ? holdingID : null,
      merchantGroupID: groupID !== "" ? groupID : null,
      merchantID: merchantID !== "" ? merchantID : null,
      isoID: isoID,
      iso: {
        callbackURL: "https://webhook.site/dd4eadec-dab5-4397-9754-c45738f2fb9e",
        timeStamp: timestamp,
      },
      fiat: {
        currencyCode: currency,
      },
      report: {
        date: reportDate.replaceAll("-", ""),
      },
    };

    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      acquirerName: acquirerData.acquirerName,
      acquirerID: acquirerData.acquirerId,
    };

    try {
      const response = await fetchData({
        endpoint: "/merchant-clearing-settlement",
        data,
        headers,
        token,
      });
      if (response && response.data) {
        setMerchantData(response.data.holding || []);
        setMerchantHolding(response.data.merchantHolding)
        setMerchantHoldingID(response.data.merchantHoldingID)
        setHide(false);
        setSnackOpen(true);
        setMessageOpen("Report generated successfully!");
        setSeverity("success");
      }
    } catch (error) {
      setSnackOpen(true);
      setMessageOpen("No transaction for this date");
      setSeverity("error");
    }
  };

  const downloadCSV = () => {
    const headers = [
      "ISO Name",
      "Transactions Amount",
      "Transactions Count",
      "Amount Due",
      "Holding Name",
      "Holding ID",
      "Holding Transactions Amount",
      "Holding Transactions Count",
      "Holding Amount Due",
      "Group Name",
      "Group ID",
      "Group Transactions Amount",
      "Group Transactions Count",
      "Group Amount Due",
      "Group Tx MDR",
      "Group Tx Processing",
    ];
  
    const formatDecimal = (value) => {
      return typeof value === "number" ? value.toFixed(3) : value;
    };
  
    const rows = [];
  
    merchantData.forEach((iso) => {
      (iso.holding || []).forEach((holding) => {
        const baseRow = [
          iso.isoName,
          formatDecimal(iso.transactionsAmount),
          iso.transactionsCount,
          formatDecimal(iso.amountDue),
          holding.merchantHolding,
          holding.merchantHoldingID,
          formatDecimal(holding.transactionsAmount),
          holding.transactionsCount,
          formatDecimal(holding.amountDue),
        ];
  
        if (holding.groups && holding.groups.group) {
          holding.groups.group.forEach((group) => {
            rows.push([
              ...baseRow,
              group.merchantGroup,
              group.merchantGroupID,
              formatDecimal(group.transactionsAmount),
              group.transactionsCount,
              formatDecimal(group.amountDue),
              formatDecimal(group.txMDR),
              formatDecimal(group.txProcessing),
            ]);
          });
        } else {
          rows.push([...baseRow, "", "", "", "", "", "", ""]);
        }
      });
  
      if (!(iso.holding || []).length) {
        rows.push([iso.isoName, formatDecimal(iso.transactionsAmount), iso.transactionsCount, formatDecimal(iso.amountDue), "", "", "", "", "", "", "", "", "", "", "", ""]);
      }
    });
  
    const csvContent = [headers, ...rows]
      .map((row) => row.map((cell) => `"${cell}"`).join(",")) 
      .join("\n");
  
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "iso_report.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  
  const renderTerminalsTable = (terminals) => (
    <Accordion style={{ marginTop: "20px" }}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="subtitle1">Terminals</Typography>
      </AccordionSummary>
      <AccordionDetails>
        {terminals.map((terminal, index) => (
          <Accordion key={index} style={{ marginBottom: "10px" }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>
                {terminal.terminalID} - {terminal.exchangeID}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell align="center" style={{ fontWeight: "bold" }}>Transactions Amount</TableCell>
                    <TableCell align="center" style={{ fontWeight: "bold" }}>Transactions Count</TableCell>
                    <TableCell align="center" style={{ fontWeight: "bold" }}>Tx MDR</TableCell>
                    <TableCell align="center" style={{ fontWeight: "bold" }}>Tx Processing</TableCell>
                    <TableCell align="center" style={{ fontWeight: "bold" }}>Amount Due</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell align="center">{terminal.transactionsAmount}</TableCell>
                    <TableCell align="center">{terminal.transactionsCount}</TableCell>
                    <TableCell align="center">{terminal.txMDR}</TableCell>
                    <TableCell align="center">{terminal.txProcessing}</TableCell>
                    <TableCell align="center">{terminal.amountDue}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </AccordionDetails>
          </Accordion>
        ))}
      </AccordionDetails>
    </Accordion>
  );

  const renderMerchantsTable = (merchants) => (
    <Accordion style={{ marginTop: "20px" }}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="subtitle1">Merchants</Typography>
      </AccordionSummary>
      <AccordionDetails>
        {merchants.map((merchant, index) => (
          <Accordion key={index} style={{ marginBottom: "10px" }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>
                {merchant.name} - {merchant.ID} - {merchant.store}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell align="center" style={{ fontWeight: "bold" }}>Transactions Amount</TableCell>
                    <TableCell align="center" style={{ fontWeight: "bold" }}>Transactions Count</TableCell>
                    <TableCell align="center" style={{ fontWeight: "bold" }}>Tx MDR</TableCell>
                    <TableCell align="center" style={{ fontWeight: "bold" }}>Tx Processing</TableCell>
                    <TableCell align="center" style={{ fontWeight: "bold" }}>Amount Due</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell align="center">${parseFloat(merchant.transactionsAmount || 0).toFixed(4)}</TableCell>
                    <TableCell align="center">{merchant.transactionsCount}</TableCell>
                    <TableCell align="center">${parseFloat(merchant.txMDR || 0).toFixed(4)}</TableCell>
                    <TableCell align="center">${parseFloat(merchant.txProcessing || 0).toFixed(4)}</TableCell>
                    <TableCell align="center">${parseFloat(merchant.amountDue || 0).toFixed(4)}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              {merchant.terminals && merchant.terminals.terminal && renderTerminalsTable(merchant.terminals.terminal)}
            </AccordionDetails>
          </Accordion>
        ))}
      </AccordionDetails>
    </Accordion>
  );

  const renderGroupsTable = (groups) => (
    <Accordion style={{ marginTop: "20px" }}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="subtitle1">Groups</Typography>
      </AccordionSummary>
      <AccordionDetails>
        {groups.map((group, index) => (
          <Accordion key={index} style={{ marginBottom: "10px" }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>
                {group.merchantGroup} - {group.merchantGroupID}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell align="center" style={{ fontWeight: "bold" }}>Transactions Amount</TableCell>
                    <TableCell align="center" style={{ fontWeight: "bold" }}>Transactions Count</TableCell>
                    <TableCell align="center" style={{ fontWeight: "bold" }}>Tx MDR</TableCell>
                    <TableCell align="center" style={{ fontWeight: "bold" }}>Tx Processing</TableCell>
                    <TableCell align="center" style={{ fontWeight: "bold" }}>Amount Due</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell align="center">{group.transactionsAmount}</TableCell>
                    <TableCell align="center">{group.transactionsCount}</TableCell>
                    <TableCell align="center">{group.txMDR}</TableCell>
                    <TableCell align="center">{group.txProcessing}</TableCell>
                    <TableCell align="center">{group.amountDue}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              {group.merchants && group.merchants.merchant && renderMerchantsTable(group.merchants.merchant)}
            </AccordionDetails>
          </Accordion>
        ))}
      </AccordionDetails>
    </Accordion>
  );

  const renderHoldingsTable = (holdings) => (
    <Accordion style={{ marginTop: "20px" }}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="subtitle1">Holdings</Typography>
      </AccordionSummary>
      <AccordionDetails>
        {holdings.map((holding, index) => (
          <Accordion key={index} style={{ marginBottom: "10px" }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>
                {holding.merchantHolding} - {holding.merchantHoldingID}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell align="center" style={{ fontWeight: "bold" }}>Transactions Amount</TableCell>
                    <TableCell align="center" style={{ fontWeight: "bold" }}>Transactions Count</TableCell>
                    <TableCell align="center" style={{ fontWeight: "bold" }}>Tx MDR</TableCell>
                    <TableCell align="center" style={{ fontWeight: "bold" }}>Tx Processing</TableCell>
                    <TableCell align="center" style={{ fontWeight: "bold" }}>Amount Due</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell align="center">{holding.transactionsAmount}</TableCell>
                    <TableCell align="center">{holding.transactionsCount}</TableCell>
                    <TableCell align="center">{holding.txMDR}</TableCell>
                    <TableCell align="center">{holding.txProcessing}</TableCell>
                    <TableCell align="center">{holding.amountDue}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              {holding.groups && holding.groups.group && renderGroupsTable(holding.groups.group)}
            </AccordionDetails>
          </Accordion>
        ))}
      </AccordionDetails>
    </Accordion>
  );

  return (
    <>
      <form onSubmit={handleSubmit} style={{ margin: "20px 0" }}>
        <Grid container spacing={3}>
        <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              id="holding-id"
              label="Merchant Holding ID"
              value={holdingID}
              onChange={(event) => setHoldingID(event.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              id="group-id"
              label="Merchant Group ID"
              value={groupID}
              onChange={(event) => setGroupID(event.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              id="merchant-id"
              label="Merchant ID"
              value={merchantID}
              onChange={(event) => setMerchantID(event.target.value)}
              required={groupID === '' && holdingID === ''}
            />
          </Grid>          
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              id="iso-id"
              label="ISO ID"
              value={isoID || ""}
              onChange={(event) => setIsoID(event.target.value)}
              disabled 
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl fullWidth disabled> 
              <InputLabel id="currency-label">Currency Code</InputLabel>
              <Select
                labelId="currency-label"
                id="currency"
                value={currency || ""}
                label="Currency Code"
                onChange={(event) => setCurrency(event.target.value)}
                required
              >
                <MenuItem value="USD">USD</MenuItem>
                <MenuItem value="BRL">BRL</MenuItem> 
                <MenuItem value="MXN">MXN</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              id="report-date"
              label="Report Date"
              type="date"
              value={reportDate}
              onChange={handleDateChange}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                min: new Date(new Date().setDate(new Date().getDate() - 90))
                  .toISOString()
                  .split("T")[0], 
                max: new Date(new Date().setDate(new Date().getDate() - 1))
                  .toISOString()
                  .split("T")[0], 
              }}
              required
            />
          </Grid>
          <Grid item xs={12} textAlign={isMobile ? "center" : "left"} style={{ marginTop: "20px" }}>
            <Button variant="contained" type="submit" size="large">
              GENERATE
            </Button>
            {merchantData.length > 0 && (
              <Button
                variant="outlined"
                style={{ marginLeft: "10px" }}
                onClick={downloadCSV}
                disabled={hide}
              >
                Download CSV
              </Button>
            )}
          </Grid>
        </Grid>
      </form>

      {merchantData.map((merchant, index) => (
        <Accordion key={index} style={{ marginTop: "20px" }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6">{merchantHolding} | {merchantHoldingID} </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <TableContainer component={Paper} style={{ marginBottom: "20px", padding: "10px" }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="center" style={{ fontWeight: "bold" }}>Transactions Amount</TableCell>
                    <TableCell align="center" style={{ fontWeight: "bold" }}>Transactions Count</TableCell>
                    <TableCell align="center" style={{ fontWeight: "bold" }}>Tx MDR</TableCell>
                    <TableCell align="center" style={{ fontWeight: "bold" }}>Tx Processing</TableCell>
                    <TableCell align="center" style={{ fontWeight: "bold" }}>Amount Due</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell align="center">{merchant.transactionsAmount}</TableCell>
                    <TableCell align="center">{merchant.transactionsCount}</TableCell>
                    <TableCell align="center">{merchant.txMDR}</TableCell>
                    <TableCell align="center">{merchant.txProcessing}</TableCell>
                    <TableCell align="center">{merchant.amountDue}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            {renderHoldingsTable(merchant.groups.group)}
          </AccordionDetails>
        </Accordion>
      ))}
    </>
  );
};

export default MerchantDailyReportForm;
