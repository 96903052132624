export const initialFormData = {
    iso: {
        isoName: "",
        address: "", 
        neighborhood: "", 
        city: "", 
        county: "", 
        state: "", 
        postalCode: "", 
        countryCode: "", 
        amountTxProcessing:"", 
        amountTxProcessingTiers:"", 
        percentTxProcessing: "", 
        percentTxProcessingTiers: "", 
        amountTerminalMaintenance: "", 
        amountTerminalMaintenanceTiers: "", 
        terminalMaintenanceInactive: "", 
        amountTerminalSetup: "", 
        amountTerminalSetupTiers: "", 
        effectiveDate: "" 
    },
    bank: {
        bid: "",
        swift_code: "",
        account_number: "",
        address: "",
        routing_number: "",
        iban: "",
        branch_number: "",
        city: "",
        state: "",
        country_code: "",
        postal_code: "",
    },
};
  