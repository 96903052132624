import * as React from "react";
import { useState, useEffect, useContext } from "react";
import { styled } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import MuiDrawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import AssessmentIcon from "@mui/icons-material/Assessment";
import DataTable from "../components/table";
import FormMerchantTerminal from "../components/forms/MerchantTerminalForm";
import FormReport from "../components/forms/ReportForm";
import SearchTerminalForm from "../components/forms/SearchTerminalForm";
import IsoDailyReportForm from "../components/forms/IsoDailyReport";
import MerchantDailyReportForm from "../components/forms/MerchantDailyReport"; 
import ClearingSettlementReportForm from "../components/forms/ClearingSettlement";
import TransactionDailyReportForm from "../components/forms/DailyTransactionsReport";
import TransactionSearch from "../components/forms/TransactionsSearch";
import IsoEnrollmentForm from "../components/forms//iso_enrollment/IsoEnrollmentForm";
import IsoUpdate from "../components/forms//iso_enrollment/IsoUpdate";

// import SearchForm from "./search_form";
// import PeriodicForm from "./periodic_form";
import Logo from "../assets/images/logo.jpeg";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
// import SearchIcon from "@mui/icons-material/Search";
// import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import AuthContext from "../context/AuthContext";

import StoreIcon from "@mui/icons-material/Store";
import EditIcon from "@mui/icons-material/Edit";
import ReceiptIcon from "@mui/icons-material/Receipt";
import SearchIcon from "@mui/icons-material/Search";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import UpdateIcon from "@mui/icons-material/Update";
import SummarizeIcon from "@mui/icons-material/Summarize";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import BarChartIcon from "@mui/icons-material/BarChart";
import DescriptionIcon from "@mui/icons-material/Description";

const StyledImage = styled("img")({
  width: "100%",
  height: "auto",
});

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="#">
        Blokko
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

export default function Dashboard() {
  const { userInfo, acquirerData, logout } = useContext(AuthContext);
  const [open, setOpen] = useState(true);
  const [data, setData] = useState({});
  const [openSnack, setSnackOpen] = useState(false);
  const [rol, setRol] = useState(false);
  const [messageSnack, setMessageOpen] = useState(
    "No transaction for this date"
  );
  const [selectedOption, setSelectedOption] = useState("");
  const [severity, setSeverity] = useState("warning");
  const [nameSection, setNameSection] = useState("Home");
  const [submenuOpen, setSubmenuOpen] = useState(false);
  const [submenuMerchantTerminal, setSubmenuMerchantTerminal] = useState(null);
  const [submenuReportsOpen, setSubmenuReportsOpen] = useState(false);
  const [submenuAdministrativeOpen, setSubmenuAdministrativeOpen] =
    useState(false);
  const [submenuReportsOption, setSubmenuReportsOption] = useState(null);
  const [submenuAdministrativeOption, setSubmenuAdministrativeOption] =
    useState(null);
  const [role, setRole] = useState(null);

  useEffect(() => {
    if (userInfo) {
      setRole(userInfo["acquirer-rol-data"]);
      //setRole("merchant");
    }
  }, [userInfo]);

  const hasAccess = (allowedRoles) => allowedRoles.includes(role);

  const toggleAdministrativeSubmenu = () => {
    setSubmenuAdministrativeOpen((prev) => !prev);
  };

  useEffect(() => {
    setData({});
  }, [selectedOption]);

  const toggleReportsSubmenu = () => {
    setSubmenuReportsOpen((prev) => !prev);
  };

  const toggleSubmenu = () => {
    setSubmenuOpen((prev) => !prev);
  };

  useEffect(() => {
    if (acquirerData?.acquirerRol === -99) {
      setRol(true);
    }
  }, [acquirerData]);

  const handleClose = () => {
    setSnackOpen(false);
  };

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const handleLogout = () => {
    logout();
  };

  return (
    <Box sx={{ display: "flex" }}>
      <Snackbar
        open={openSnack}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert severity={severity} onClose={handleClose}>
          {messageSnack}
        </Alert>
      </Snackbar>
      <CssBaseline />
      <AppBar position="absolute" open={open}>
        <Toolbar
          sx={{
            pr: "24px", // keep right padding when drawer closed
          }}
        >
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={toggleDrawer}
            sx={{
              marginRight: "36px",
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            sx={{ flexGrow: 1 }}
          >
            {nameSection} / {acquirerData.acquirerId} /{" "}
            {acquirerData.acquirerName} / {userInfo.email} /{" "}
            {userInfo["acquirer-rol-data"]}
          </Typography>
          <Button color="inherit" onClick={handleLogout}>
            Logout
          </Button>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <Toolbar
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            px: [1],
          }}
        >
          <StyledImage
            src={Logo}
            alt="Blokko"
            style={{
              width: "120px",
              height: "auto",
              objectFit: "contain",
            }}
          />
          <IconButton onClick={toggleDrawer}>
            <ChevronLeftIcon />
          </IconButton>
        </Toolbar>
        <Divider />
        <List component="nav">
          {rol && (
            <>
              {hasAccess(["admin", "ISO"]) && (
                <>
                  <ListItemButton onClick={toggleSubmenu}>
                    <ListItemIcon>
                      <StoreIcon />
                    </ListItemIcon>
                    <ListItemText primary="Merchant & Terminal" />
                  </ListItemButton>
                  {submenuOpen && (
                    <List component="div" disablePadding>
                      <ListItemButton
                        sx={{ pl: 4 }}
                        selected={submenuMerchantTerminal === "enrollment"}
                        onClick={() => {
                          setSubmenuMerchantTerminal("enrollment");
                          setSelectedOption("merchant_terminal_enrollment");
                          setNameSection("Merchant & Terminal - Enrollment");
                        }}
                      >
                        <ListItemIcon>
                          <PersonAddIcon />
                        </ListItemIcon>
                        <ListItemText primary="Enrollment" />
                      </ListItemButton>
                      <ListItemButton
                        sx={{ pl: 4 }}
                        selected={
                          submenuMerchantTerminal === "edit_unenrollment"
                        }
                        onClick={() => {
                          setSubmenuMerchantTerminal("edit_unenrollment");
                          setSelectedOption(
                            "merchant_terminal_edit_unenrollment"
                          );
                          setNameSection(
                            "Merchant & Terminal - Edit & Unenrollment"
                          );
                        }}
                      >
                        <ListItemIcon>
                          <EditIcon />
                        </ListItemIcon>
                        <ListItemText primary="Edit & Unenrollment" />
                      </ListItemButton>
                    </List>
                  )}
                </>
              )}
            </>
          )}

          {/* Reports */}
          {hasAccess(["admin", "ISO", "merchant"]) && (
            <>
              <ListItemButton onClick={toggleReportsSubmenu}>
                <ListItemIcon>
                  <AssessmentIcon />
                </ListItemIcon>
                <ListItemText primary="Reports" />
              </ListItemButton>
              {submenuReportsOpen && (
                <List component="div" disablePadding>
                  {hasAccess(["admin", "merchant"]) &&
                    <>
                      <ListItemButton
                        sx={{ pl: 4 }}
                        selected={submenuReportsOption === "daily_report"}
                        onClick={() => {
                          setSubmenuReportsOption("daily_report");
                          setSelectedOption("daily_report");
                          setNameSection("Reports - Daily Clearing & Settlement Report");
                        }}
                      >
                        <ListItemIcon>
                          <SummarizeIcon />
                        </ListItemIcon>
                        <ListItemText primary="Acquirer Daily" />
                      </ListItemButton>

                      <ListItemButton
                        sx={{ pl: 4 }}
                        selected={submenuReportsOption === "merchant_report"}
                        onClick={() => {
                          setSubmenuReportsOption("merchant_report");
                          setSelectedOption("merchant_report");
                          setNameSection("Reports -  Merchant Daily Clearing & Settlement Report");
                        }}
                      >
                        <ListItemIcon>
                          <AccountBalanceIcon />
                        </ListItemIcon>
                        <ListItemText primary="Merchant Daily" />
                      </ListItemButton>   
                      
                      <ListItemButton
                        sx={{ pl: 4 }}
                        selected={submenuReportsOption === "periodic_daily_clearing_settlement"}
                        onClick={() => {
                          setSubmenuReportsOption("periodic_daily_clearing_settlement");
                          setSelectedOption("periodic_daily_clearing_settlement");
                          setNameSection("Reports - Periodic Daily Clearing Settlement");
                        }}
                      >
                        <ListItemIcon>
                          <BarChartIcon />
                        </ListItemIcon>
                        <ListItemText primary="Clearing Settlement" />
                      </ListItemButton>
                    </>
                  }

                  {hasAccess(["admin", "ISO"]) && (
                    <>
                      <ListItemButton
                        sx={{ pl: 4 }}
                        selected={submenuReportsOption === "iso_report"}
                        onClick={() => {
                          setSubmenuReportsOption("iso_report");
                          setSelectedOption("iso_report");
                          setNameSection(
                            "Reports -  ISO Daily Clearing & Settlement Report"
                          );
                        }}
                      >
                        <ListItemIcon>
                          <DescriptionIcon />
                        </ListItemIcon>
                        <ListItemText primary="ISO Daily" />
                      </ListItemButton>
                    </>
                  )}
                  {hasAccess(["admin", "merchant", "ISO"]) && (
                    <>
                      <ListItemButton
                        sx={{ pl: 4 }}
                        selected={submenuReportsOption === "transaction_report"}
                        onClick={() => {
                          setSubmenuReportsOption("transaction_report");
                          setSelectedOption("transaction_report");
                          setNameSection("Reports - Daily Transactions Report");
                        }}
                      >
                        <ListItemIcon>
                          <ReceiptIcon />
                        </ListItemIcon>
                        <ListItemText primary="Daily Transactions" />
                      </ListItemButton>

                      <ListItemButton
                        sx={{ pl: 4 }}
                        selected={submenuReportsOption === "transaction_search"}
                        onClick={() => {
                          setSubmenuReportsOption("transaction_search");
                          setSelectedOption("transaction_search");
                          setNameSection("Reports - Transactions Search");
                        }}
                      >
                        <ListItemIcon>
                          <SearchIcon />
                        </ListItemIcon>
                        <ListItemText primary="Transactions Search" />
                      </ListItemButton>  

                    </>      
                  )}   

                </List>
              )}
            </>
          )}
          {/* Administrative */}
          {hasAccess(["admin"]) && (
            <>
              <ListItemButton onClick={toggleAdministrativeSubmenu}>
                <ListItemIcon>
                  <AdminPanelSettingsIcon />
                </ListItemIcon>
                <ListItemText primary="Administrative" />
              </ListItemButton>
              {submenuAdministrativeOpen && (
                <List component="div" disablePadding>
                  {hasAccess(["admin", "ISO"]) && (
                    <ListItemButton
                      sx={{ pl: 4 }}
                      selected={
                        submenuAdministrativeOption === "iso_enrollment"
                      }
                      onClick={() => {
                        setSubmenuAdministrativeOption("iso_enrollment");
                        setSelectedOption("iso_enrollment");
                        setNameSection("Administrative - ISO Enrollment");
                      }}
                    >
                      <ListItemIcon>
                        <PersonAddIcon />
                      </ListItemIcon>
                      <ListItemText primary="ISO Enrollment" />
                    </ListItemButton>
                  )}

                  {hasAccess(["admin"]) && (
                    <ListItemButton
                      sx={{ pl: 4 }}
                      selected={submenuAdministrativeOption === "iso_updated"}
                      onClick={() => {
                        setSubmenuAdministrativeOption("iso_updated");
                        setSelectedOption("iso_updated");
                        setNameSection("Administrative - ISO Updated");
                      }}
                    >
                      <ListItemIcon>
                        <UpdateIcon />
                      </ListItemIcon>
                      <ListItemText primary="ISO Updated" />
                    </ListItemButton>
                  )}
                </List>
              )}
            </>
          )}

          {/* <ListItemButton
            selected={selectedOption === "search"}
            onClick={() => {
              setSelectedOption("search");
              setNameSection("Transaction Search");
            }}
          >
            <ListItemIcon>
              <SearchIcon />
            </ListItemIcon>
            <ListItemText primary="Transaction Search" />
          </ListItemButton>
          <ListItemButton
            selected={selectedOption === "periodic_report"}
            onClick={() => {
              setSelectedOption("periodic_report");
              setNameSection("Periodic Report");
            }}
          >
            <ListItemIcon>
              <ReceiptLongIcon />
            </ListItemIcon>
            <ListItemText primary="Periodic Report" />
          </ListItemButton> */}
        </List>
      </Drawer>
      {selectedOption && (
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[300]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
          }}
        >
          <Toolbar />
          <Container
            maxWidth="lg"
            sx={{
              mt: 4,
              mb: 4,
              backgroundColor: (theme) =>
                theme.palette.mode === "light" ? "#fff" : theme.palette.grey[800],
              padding: 2,
              borderRadius: 2, 
              boxShadow: 3, 
            }}
          >
            {selectedOption === "merchant_terminal_enrollment" && (
              <FormMerchantTerminal
                setData={setData}
                setSnackOpen={setSnackOpen}
                setMessageOpen={setMessageOpen}
                setSeverity={setSeverity}
              />
            )}
            {selectedOption === "merchant_terminal_edit_unenrollment" && (
              <SearchTerminalForm
                setData={setData}
                setSnackOpen={setSnackOpen}
                setMessageOpen={setMessageOpen}
                setSeverity={setSeverity}
              />
            )}
            {selectedOption === "daily_report" && (
              <FormReport
                type="acquirer"
                setData={setData}
                setSnackOpen={setSnackOpen}
                setMessageOpen={setMessageOpen}
                setSeverity={setSeverity}
              />
            )}

            {selectedOption === "iso_report" && (
              <IsoDailyReportForm
                type="iso"
                setData={setData}
                setSnackOpen={setSnackOpen}
                setMessageOpen={setMessageOpen}
                setSeverity={setSeverity}
              />
            )}

            {selectedOption === "merchant_report" && (
              <MerchantDailyReportForm
                type="merchant"
                setData={setData}
                setSnackOpen={setSnackOpen}
                setMessageOpen={setMessageOpen}
                setSeverity={setSeverity}
              />
            )}

            {selectedOption === "transaction_report" && (
              <TransactionDailyReportForm
                type="transaction"
                setData={setData}
                setSnackOpen={setSnackOpen}
                setMessageOpen={setMessageOpen}
                setSeverity={setSeverity}
              />
            )}

            {selectedOption === "transaction_search" && (
              <TransactionSearch
                type="transaction_search"
                setData={setData}
                setSnackOpen={setSnackOpen}
                setMessageOpen={setMessageOpen}
                setSeverity={setSeverity}
              />
            )} 

            {selectedOption === "periodic_daily_clearing_settlement" && (
              <ClearingSettlementReportForm
                type="periodic_daily_clearing_settlement"
                setData={setData}
                setSnackOpen={setSnackOpen}
                setMessageOpen={setMessageOpen}
                setSeverity={setSeverity}
              />
            )}

            {selectedOption === "iso_enrollment" && (
              <IsoEnrollmentForm
                type="iso_enrollment"
                setData={setData}
                setSnackOpen={setSnackOpen}
                setMessageOpen={setMessageOpen}
                setSeverity={setSeverity}
              />
            )}

            {selectedOption === "iso_updated" && (
              <IsoUpdate
                type="iso_updated"
                setData={setData}
                setSnackOpen={setSnackOpen}
                setMessageOpen={setMessageOpen}
                setSeverity={setSeverity}
              />
            )}
            {/* {selectedOption === "search" && (
              <SearchForm setData={setData} setSnackOpen={setSnackOpen} setMessageOpen={setMessageOpen} setSeverity={setSeverity}/>
            )}
            {selectedOption === "periodic_report" && (
              <PeriodicForm setData={setData} setSnackOpen={setSnackOpen}  setMessageOpen={setMessageOpen} setSeverity={setSeverity}/>
            )} */}
          </Container>
          {data.holdings?.holding?.length > 0 && (
            <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
              <Grid container spacing={3}>
                <DataTable data={data} />
              </Grid>
              <Copyright sx={{ pt: 4 }} />
            </Container>
          )}
        </Box>
      )}
    </Box>
  );
}
