import { fetchData } from "../apiService";

const createAcquirerData = (formData) => {
  const data = {
    iso: formData.acquirer.iso || false,
    merchantHolding: String(formData.acquirer.merchantHolding || formData.acquirer.holding || ""),
    merchantHoldingID: String(formData.acquirer.merchantHoldingID || formData.acquirer.holdingId || ""),
    merchantGroup: String(formData.acquirer.merchantGroup || formData.acquirer.group || ""),
    merchantGroupID: String(formData.acquirer.merchantGroupID || formData.acquirer.groupId || ""),
    merchantName: String(formData.acquirer.merchantName || formData.acquirer.name || ""),
    merchantStore: String(formData.acquirer.merchantStore || formData.acquirer.store || ""),
    merchantAddress: String(formData.acquirer.merchantAddress || formData.acquirer.address || ""),
    merchantNeighborhood: String(formData.acquirer.merchantNeighborhood || formData.acquirer.neighborhood || ""),
    merchantCity: String(formData.acquirer.merchantCity || formData.acquirer.city || ""),
    merchantCounty: String(formData.acquirer.merchantCounty || formData.acquirer.country || ""),
    merchantState: String(formData.acquirer.merchantState || formData.acquirer.state || ""),
    merchantPostalCode: String(formData.acquirer.merchantPostalCode || formData.acquirer.postalCode || ""),
    merchantGeoLocation: String(formData.acquirer.merchantGeoLocation || ""),
    mccID: String(formData.acquirer.mccID || ""),
    merchantID: String(formData.acquirer.merchantID || ""),
    enableAmountOther: formData.acquirer.amountOther || false,
    timeStamp: new Date().toISOString(),
    terminal: {
      terminals: formData.acquirer.terminal.terminals.map((terminal) => ({
        terminalID: String(terminal.terminalID),
        terminalType: String(terminal.terminalType || ""),
        terminalSerial: String(terminal.terminalSerial),
      })),
    },
  };

  if (formData.acquirer.iso) {
    data.isoID = String(formData.acquirer.isoID || "");
  }

  return data;
};

  
const createMerchantData = (formData) => {
  return {
    /*
    incorporationType: String(
      formData.merchant.incorporation_type || formData.merchant.incorporationType || ""
    ),
    */
    incorporationDate: String(
      formData.merchant.incorporation_date || formData.merchant.incorporationDate || ""
    ).replace(/-/g, ""),
    taxpayerID: String(
      formData.merchant.taxpayer_id || formData.merchant.taxpayerID || ""
    ),
    legalName: String(
      formData.merchant.legal_name || formData.merchant.legalName || ""
    ),
    businessType: String(
      formData.merchant.business_type || formData.merchant.businessType || ""
    ),
    businessPhone: String(
      formData.merchant.business_phone || formData.merchant.businessPhone || ""
    ),
    businessEmail: String(
      formData.merchant.business_email || formData.merchant.businessEmail || ""
    ),
    amountTxProcessing: String(
      formData.merchant.amount_tx_processing || formData.merchant.amountTxProcessing || ""
    ),
    amountTxProcessingTiers: String(
      formData.merchant.amount_tx_processing_tiers || formData.merchant.amountTxProcessingTiers || ""
    ),
    percentTxProcessing: String(
      formData.merchant.percent_tx_processing || formData.merchant.percentTxProcessing || ""
    ),
    percentTxProcessingTiers: String(
      formData.merchant.percent_tx_processing_tiers || formData.merchant.percentTxProcessingTiers || ""
    ),
    amountTerminalMaintenance: String(
      formData.merchant.amount_terminal_maintenance || formData.merchant.amountTerminalMaintenance || ""
    ),
    amountTerminalMaintenanceTiers: String(
      formData.merchant.amount_terminal_maintenance_tiers || formData.merchant.amountTerminalMaintenanceTiers || ""
    ),
    terminalMaintenanceInactive:
      formData.merchant.terminal_maintenance_inactive ||
      formData.merchant.terminalMaintenanceInactive ||
      false, // Boolean value
    amountTerminalSetup: String(
      formData.merchant.amount_terminal_setup || formData.merchant.amountTerminalSetup || ""
    ),
    amountTerminalSetupTiers: String(
      formData.merchant.amount_terminal_setup_tiers || formData.merchant.amountTerminalSetupTiers || ""
    ),
    effectiveDate: String(
      formData.merchant.effective_date || formData.merchant.effectiveDate || ""
    ).replace(/-/g, ""),
  };
};

const createOwnerData = (formData) => {
  if (Array.isArray(formData.owner) && formData.owner.length > 0) {
    return formData.owner.map(owner => {
      return {
        percentage: String(Math.trunc(Number(owner.percentage)) || ""),
        firstName: String(owner.first_name || owner.firstName || ""),
        middleName: String(owner.middle_name || owner.middleName || ""),
        lastName: String(owner.last_name || owner.lastName || ""),
        taxpayerID: String(owner.taxpayer_id || owner.taxpayerID || ""),
        ID: String(owner.id || ""),
        idIssuer: String(owner.id_issuer || owner.idIssuer || ""),
        address: String(owner.address || ""),
        neighborhood: String(owner.neighborhood || ""),
        city: String(owner.city || ""),
        county: String(owner.county || ""),
        state: String(owner.state || ""),
        postalCode: String(owner.postal_code || owner.postalCode || ""),
        country: String(owner.country || ""),
        residencyTime: String(owner.residency_time || owner.residencyTime || ""),
        personalPhone: String(owner.personal_phone || owner.personalPhone || ""),
        personalEmail: String(owner.personal_email || owner.personalEmail || ""),
      };
    });
  }

  return [];
};

const createBankData = (formData) => {
  return {
    accountNumber: String(formData.bank.account_number || formData.bank.accountNumber || ""),
    Iban: String(formData.bank.iban || formData.bank.Iban || ""),
    swiftCode: String(formData.bank.swift_code || formData.bank.swiftCode || ""),
    routingNumber: String(formData.bank.routing_number || formData.bank.routingNumber || ""),
    branchNumber: String(formData.bank.branch_number || formData.bank.branchNumber || ""),
    Bid: String(formData.bank.bid || formData.bank.Bid || ""),
    address: String(formData.bank.address || ""),
    city: String(formData.bank.city || ""),
    state: String(formData.bank.state || ""),
    postalCode: String(formData.bank.postal_code || formData.bank.postalCode || ""),
  };
};

const createFiatData = (formData) => ({
  currencyCode: formData.currencyCode !== undefined ? formData.currencyCode : formData.acquirer.currency || formData.fiat.currencyCode ,
});


export const enrollmentTerminal = async (endpoint, formData, token, acquirerData) => {

  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
    acquirerName: acquirerData.acquirerName,
    acquirerID: acquirerData.acquirerId
  };

  const data = {
    version: "04.09.01",
    acquirer: createAcquirerData(formData),
    fiat: createFiatData(formData),
  };

  if (formData.merchant && isValid(formData.merchant)) {
    data.merchant = createMerchantData(formData);
  }

  if (formData.owner && isValidOwner(formData.owner)) {
    data.owner = createOwnerData(formData);
  }
  
  if (formData.bank && isValid(formData.bank)) {
    data.bank = createBankData(formData);
  }
  try { 
    const response = await fetchData({ endpoint, data, headers, token });
    return response;
  } catch (error) {
    console.error("Error updating terminal:", error);
    throw error;
  }
};

function isValidOwner(ownerArray) {
  if (!Array.isArray(ownerArray) || ownerArray.length === 0) {
    return false;
  }
  return ownerArray.some(owner => 
    Object.values(owner).some(value => value !== "")
  );
}

function isValid(data) {
  return Object.values(data).some(value => value !== "");
}