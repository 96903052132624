import React, { useState, useContext } from "react";
import {
  Button,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  useTheme,
  useMediaQuery,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  TableHead,
  Card,
  CardContent,
	TablePagination,
	Menu,
  IconButton,
	Box
} from "@mui/material";
import * as XLSX from "xlsx";
import { FileDownload } from "@mui/icons-material";
import AuthContext from "../../context/AuthContext";
import { fetchData } from "../../services/apiService";
import FilterListIcon from "@mui/icons-material/FilterList";

const TransactionSearch = ({ setSnackOpen, setMessageOpen, setSeverity }) => {
  const { token, acquirerData } = useContext(AuthContext);
  const [merchantID, setMerchantID] = useState("");
  const [terminalID, setTerminalID] = useState("");
  const [currency, setCurrency] = useState("");
  const [amount, setAmount] = useState("");
  const [exchangeID, setExchangeID] = useState("");
  const [requestDate, setRequestDate] = useState("");
  const [responseData, setResponseData] = useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

	const exportToExcel = () => {
		const transactions = responseData?.terminals?.flatMap(terminal => terminal.transactions || []) || [];
	
		if (!transactions.length) {
			alert("No transactions available to export.");
			return;
		}
	
		const merchantName = responseData?.acquirer?.merchantName || "N/A";
		const merchantStore = responseData?.acquirer?.merchantStore || "N/A";
		const currencyCode = responseData?.fiat?.currencyCode || "N/A";
		const terminalID = responseData?.terminals?.[0]?.terminalID || "N/A";
	
		const dataToExport = transactions.map(txn => ({
			"Merchant Name": merchantName,
			"Merchant Store": merchantStore,
			"Currency Code": currencyCode,
			"Terminal ID": terminalID,
			"Transaction ID": txn.acquirer?.transactionID || "N/A",
			"Amount": parseFloat(txn.transactionAmount || 0).toFixed(2),
			"Currency": txn.crypto?.currencySymbol || "N/A",
			"Exchange ID": txn.crypto?.exchangeID || "N/A",
			"Exchange Commission": parseFloat(txn.exchangeCommission || 0).toFixed(2),
			"TX Processing": parseFloat(txn.txProcessing || 0).toFixed(2),
			"Date": txn.rate?.requestDate
				? `${txn.rate.requestDate.substring(6, 8)}/${txn.rate.requestDate.substring(4, 6)}/${txn.rate.requestDate.substring(0, 4)}`
				: "N/A",
			"Time": txn.rate?.requestTime || "N/A",
			"Status": txn.blokko?.transactionStatus || "N/A",
		}));
	
		const ws = XLSX.utils.json_to_sheet(dataToExport);
		const wb = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(wb, ws, "Transactions");
		XLSX.writeFile(wb, "Transaction_Report.xlsx");
	};	

  const handleSubmit = async (event) => {
    event.preventDefault();
	let formattedAmount = '';
	if (amount) {
		formattedAmount = Number(amount).toFixed(2).padStart(16, "0");
	}
    const formattedDate = requestDate.replaceAll("-", "");
    const data = {
      version: "04.09.01",
      acquirer: {
        merchantID,
        terminalID,
        timeStamp: formattedDate,
      },
      fiat: {
        currencyCode: acquirerData.currency_code,
        amount: formattedAmount,
      },
      crypto: {
        exchangeID,
      },
      rate: {
        requestDate: formattedDate,
      },
    };

    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      acquirerName: acquirerData.acquirerName,
      acquirerID: acquirerData.acquirerId,
    };

    try {
      const response = await fetchData({
        endpoint: "/search-transaction",
        data,
        headers,
        token,
      });
      if (response) {
        setResponseData(response.data);
        setSnackOpen(true);
        setMessageOpen("Report generated successfully!");
        setSeverity("success");
      }
    } catch (error) {
      setSnackOpen(true);
      setMessageOpen("There are no transactions for the date entered!");
      setSeverity("error");
    }
  };

	const TransactionDataTable = ({ data }) => {
		const [page, setPage] = useState(0);
		const [rowsPerPage, setRowsPerPage] = useState(10);
		const [statusFilter, setStatusFilter] = useState("All");
		const [anchorEl, setAnchorEl] = useState(null); 
	
		if (!data) {
			return <Typography>No data available</Typography>;
		}
	
		const handleChangePage = (event, newPage) => {
			setPage(newPage);
		};
	
		const handleChangeRowsPerPage = (event) => {
			setRowsPerPage(parseInt(event.target.value, 10));
			setPage(0);
		};
	
		const handleFilterClick = (event) => {
			setAnchorEl(event.currentTarget);
		};
	
		const handleFilterClose = (filter) => {
			setStatusFilter(filter);
			setAnchorEl(null);
			setPage(0); 
		};
	
		const { acquirer, terminals, fiat } = data;
		const transactions = terminals?.flatMap((terminal) => terminal.transactions || []) || [];
	
		const filteredTransactions = transactions.filter((txn) => {
			if (statusFilter === "All") return true;
			return txn.blokko?.transactionStatus === statusFilter;
		});
	
		const sortedTransactions = filteredTransactions.sort((a, b) => {
			const timeA = a.rate?.requestTime || "";
			const timeB = b.rate?.requestTime || "";
			return timeB.localeCompare(timeA); 
		});
	
		return (
			<Card sx={{ mt: 3, p: 2 }}>
				<CardContent>
					<Typography variant="h6" gutterBottom>
						Merchant & Terminal Details
					</Typography>
					<TableContainer component={Paper} sx={{ mb: 3 }}>
						<Table>
							<TableHead>
								<TableRow>
									<TableCell sx={{ fontWeight: "bold" }}>Merchant Name</TableCell>
									<TableCell sx={{ fontWeight: "bold" }}>Merchant Store</TableCell>
									<TableCell sx={{ fontWeight: "bold" }}>Currency</TableCell>
									<TableCell sx={{ fontWeight: "bold" }}>Terminal ID</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								<TableRow>
									<TableCell>{acquirer?.merchantName || "N/A"}</TableCell>
									<TableCell>{acquirer?.merchantStore || "N/A"}</TableCell>
									<TableCell>{fiat?.currencyCode || "N/A"}</TableCell>
									<TableCell>{terminals[0]?.terminalID || "N/A"}</TableCell>
								</TableRow>
							</TableBody>
						</Table>
					</TableContainer>
	
					<Typography variant="h6" gutterBottom>
						Transaction Details
					</Typography>
	
					<TableContainer component={Paper}>
						<Table>
							<TableHead>
								<TableRow>
									<TableCell sx={{ fontWeight: "bold" }}>Transaction</TableCell>
									<TableCell sx={{ fontWeight: "bold" }}>Amount</TableCell>
									<TableCell sx={{ fontWeight: "bold" }}>Currency</TableCell>
									<TableCell sx={{ fontWeight: "bold" }}>Exchange</TableCell>
									<TableCell sx={{ fontWeight: "bold" }}>Exchange Commission</TableCell>
									<TableCell sx={{ fontWeight: "bold" }}>TX Processing</TableCell>
									<TableCell sx={{ fontWeight: "bold" }}>Date</TableCell>
									<TableCell sx={{ fontWeight: "bold" }}>Time</TableCell>
									<TableCell sx={{ fontWeight: "bold", position: "relative" }}>
										<Box
											component="span"
											sx={{
												display: "inline-flex",
												alignItems: "center",
												gap: 1,
											}}
										>
											Status
											<IconButton
												onClick={handleFilterClick}
												size="small"
												sx={{
													padding: 0.5,
													"&:hover": {
														backgroundColor: "rgba(0, 0, 0, 0.04)",
													},
												}}
											>
												<FilterListIcon fontSize="small" />
											</IconButton>
										</Box>
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{sortedTransactions.length > 0 ? (
									sortedTransactions
										.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
										.map((txn, index) => (
											<TableRow key={index}>
												<TableCell>{txn.acquirer?.transactionID || "N/A"}</TableCell>
												<TableCell>${parseFloat(txn.transactionAmount || 0).toFixed(4)}</TableCell>
												<TableCell>{txn.crypto?.currencySymbol || "N/A"}</TableCell>
												<TableCell>{txn.crypto?.exchangeID || "N/A"}</TableCell>
												<TableCell>${parseFloat(txn.exchangeCommission || 0).toFixed(4)}</TableCell>
												<TableCell>${parseFloat(txn.txProcessing || 0).toFixed(4)}</TableCell>
												<TableCell>
													{txn.rate?.requestDate
														? (() => {
																const dateStr = txn.rate.requestDate;
																const year = dateStr.substring(0, 4);
																const month = dateStr.substring(4, 6) - 1;
																const day = dateStr.substring(6, 8);
	
																const date = new Date(year, month, day);
																return date.toLocaleDateString("en-US", {
																	month: "2-digit",
																	day: "2-digit",
																	year: "numeric",
																});
															})()
														: "N/A"}
												</TableCell>
												<TableCell>{txn.rate?.requestTime || "N/A"}</TableCell>
												<TableCell>{txn.blokko?.transactionStatus || "N/A"}</TableCell>
											</TableRow>
										))
								) : (
									<TableRow>
										<TableCell colSpan={9} align="center">
											No transactions available
										</TableCell>
									</TableRow>
								)}
							</TableBody>
						</Table>
	
						<TablePagination
							rowsPerPageOptions={[5, 10, 25, 50]}
							component="div"
							count={sortedTransactions.length}
							rowsPerPage={rowsPerPage}
							page={page}
							onPageChange={handleChangePage}
							onRowsPerPageChange={handleChangeRowsPerPage}
						/>
					</TableContainer>
				</CardContent>
	
				<Menu
					anchorEl={anchorEl}
					open={Boolean(anchorEl)}
					onClose={() => handleFilterClose(statusFilter)}
				>
					<MenuItem onClick={() => handleFilterClose("All")}>All</MenuItem>
					<MenuItem onClick={() => handleFilterClose("E")}>Executed</MenuItem>
					<MenuItem onClick={() => handleFilterClose("P")}>Pending</MenuItem>
				</Menu>
			</Card>
		);
	};

  return (
    <>
      <form onSubmit={handleSubmit} style={{ margin: "20px 0" }}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              label="Merchant ID"
              value={merchantID}
              onChange={(e) => setMerchantID(e.target.value)}
              required
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              label="Terminal ID"
              value={terminalID}
              onChange={(e) => setTerminalID(e.target.value)}
            />
          </Grid>
					<Grid item xs={12} sm={4}>
						<FormControl fullWidth>
							<InputLabel id="currency-label">Currency Code</InputLabel>
							<Select
								labelId="currency-label"
								value={acquirerData.currency_code}
								onChange={(e) => setCurrency(e.target.value)} 
								label="Currency Code"
								required
								disabled 
							>
								<MenuItem value="USD">USD</MenuItem>
								<MenuItem value="BRL">BRL</MenuItem> 
								<MenuItem value="MXN">MXN</MenuItem>
							</Select>
						</FormControl>
					</Grid>

          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              label="Amount"
              type="number"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              label="Exchange ID"
              value={exchangeID}
              onChange={(e) => setExchangeID(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
			<TextField
							fullWidth
							label="Request Date"
							type="date"
							value={requestDate}
							onChange={(e) => setRequestDate(e.target.value)}
							InputLabelProps={{ shrink: true }}
							inputProps={{ max: new Date().toISOString().split("T")[0] }}
							required
						/>
					</Grid>
          <Grid item xs={12} textAlign={isMobile ? "center" : "left"}>
						<Button 
							variant="contained" 
							type="submit" 
							size="large" 
							sx={{ marginRight: isMobile ? 0 : 2, marginBottom: isMobile ? 2 : 0 }}
						>
							GENERATE
						</Button>
						<Button 
							variant="contained" 
							startIcon={<FileDownload />} 
							onClick={exportToExcel}
							size="large" 
							sx={{ marginLeft: isMobile ? 0 : 2 }}
						>
							DOWNLOAD
						</Button>
					</Grid>
        </Grid>
      </form>
      {responseData && <TransactionDataTable data={responseData} />}
    </>
  );
};

export default TransactionSearch;