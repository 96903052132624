import React from "react";
import { Grid, TextField, FormControl, InputLabel, Select, MenuItem } from "@mui/material";

export const bank_fields = [
    { label: "IBAN", name: "Iban", type: "text", shrink: true, maxLength: 40, required: false },
    { label: "SWIFT Code", name: "swiftCode", type: "text", shrink: true, maxLength: 16, required: false },
    { label: "Routing Number", name: "routingNumber", type: "text", shrink: true, maxLength: 9, required: false },
    { label: "Account Number", name: "accountNumber", type: "text", shrink: true, maxLength: 16, required: false },
    { label: "Branch Number", name: "branchNumber", type: "text", shrink: true, maxLength: 8, required: false },
    { label: "Bid", name: "Bid", type: "text", shrink: true, maxLength: 8, required: false },
    { label: "Bank Address", name: "address", type: "text", shrink: true, maxLength: 32, required: false },
    { label: "City", name: "city", type: "text", shrink: true, maxLength: 32, required: false },
    { label: "State", name: "state", type: "text", shrink: true, maxLength: 32, required: false },
    { label: "Postal Code", name: "postalCode", type: "text", shrink: true, maxLength: 16, required: false },    
    { label: "Country Code", name: "countryCode", type: "text", shrink: true, maxLength: 2, required: false },
];

const BankFields = ({ formData, handleInputChange }) => {
    return (
        <>
            {bank_fields.map((field) => (
                <Grid item xs={12} sm={3} key={field.name}>
                    {field.type === "select" ? (
                        <FormControl fullWidth required={field.required}>
                            <InputLabel id={`${field.name}-label`}>{field.label}</InputLabel>
                            <Select
                                labelId={`${field.name}-label`}
                                name={field.name}
                                label={field.label}
                                value={formData.bank[field.name] || ""}
                                onChange={handleInputChange}
                            >
                                {field.options && field.options.map((option) => (
                                    <MenuItem key={option} value={option}>
                                        {option}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    ) : (
                        <TextField
                            label={field.label}
                            name={field.name}
                            value={formData.bank[field.name] || ""}
                            onChange={handleInputChange}
                            InputLabelProps={{ shrink: field.shrink }}
                            inputProps={{ maxLength: field.maxLength }}
                            fullWidth
                            required={field.required}
                        />
                    )}
                </Grid>
            ))}
        </>
    );
};

export default BankFields;
