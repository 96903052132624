import React, { useState, useContext } from "react";
import {
  Button,
  Grid,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  useTheme,
  useMediaQuery,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import AuthContext from "../../context/AuthContext";
import { fetchData } from "../../services/apiService";

const IsoDailyReportForm = ({
  setSnackOpen,
  setMessageOpen,
  setSeverity,
}) => {
  const { token, acquirerData } = useContext(AuthContext);
  const [currency, setCurrency] = useState(acquirerData?.currency_code || "");
  const [isoID, setIsoID] = useState(acquirerData?.isoID || "");
  const [reportDate, setReportDate] = useState("");
  const [hide, setHide] = useState(true);
  const [isoData, setIsoData] = useState([]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleDateChange = (event) => {
    setReportDate(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const timestamp = new Date().toISOString();
    const data = {
      version: "04.09.01",
      isoID: isoID,
      iso: {
        callbackURL: "https://webhook.site/dd4eadec-dab5-4397-9754-c45738f2fb9e",
        timeStamp: timestamp,
      },
      fiat: {
        currencyCode: currency,
      },
      report: {
        date: reportDate.replaceAll("-", ""),
      },
    };

    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      acquirerName: acquirerData.acquirerName,
      acquirerID: acquirerData.acquirerId,
    };

    try {
      const response = await fetchData({
        endpoint: "/iso-clearing-settlement",
        data,
        headers,
        token,
      });
      if (response && response.data) {
        setIsoData(response.data.iso || []);
        setHide(false);
        setSnackOpen(true);
        setMessageOpen("Report generated successfully!");
        setSeverity("success");
      }
    } catch (error) {
      setSnackOpen(true);
      setMessageOpen("No transaction for this date");
      setSeverity("error");
    }
  };

  const downloadCSV = () => {
    const headers = [
      "ISO Name",
      "Transactions Amount",
      "Transactions Count",
      "Amount Due",
      "Holding Name",
      "Holding ID",
      "Holding Transactions Amount",
      "Holding Transactions Count",
      "Holding Amount Due",
      "Group Name",
      "Group ID",
      "Group Transactions Amount",
      "Group Transactions Count",
      "Group Amount Due",
      "Group Tx MDR",
      "Group Tx Processing",
    ];
  
    const formatDecimal = (value) => {
      return typeof value === "number" ? value.toFixed(3) : value;
    };
  
    const rows = [];
  
    isoData.forEach((iso) => {
      (iso.holding || []).forEach((holding) => {
        const baseRow = [
          iso.isoName,
          formatDecimal(iso.transactionsAmount),
          iso.transactionsCount,
          formatDecimal(iso.amountDue),
          holding.merchantHolding,
          holding.merchantHoldingID,
          formatDecimal(holding.transactionsAmount),
          holding.transactionsCount,
          formatDecimal(holding.amountDue),
        ];
  
        if (holding.groups && holding.groups.group) {
          holding.groups.group.forEach((group) => {
            rows.push([
              ...baseRow,
              group.merchantGroup,
              group.merchantGroupID,
              formatDecimal(group.transactionsAmount),
              group.transactionsCount,
              formatDecimal(group.amountDue),
              formatDecimal(group.txMDR),
              formatDecimal(group.txProcessing),
            ]);
          });
        } else {
          rows.push([...baseRow, "", "", "", "", "", "", ""]);
        }
      });
  
      if (!(iso.holding || []).length) {
        rows.push([iso.isoName, formatDecimal(iso.transactionsAmount), iso.transactionsCount, formatDecimal(iso.amountDue), "", "", "", "", "", "", "", "", "", "", "", ""]);
      }
    });
  
    const csvContent = [headers, ...rows]
      .map((row) => row.map((cell) => `"${cell}"`).join(",")) 
      .join("\n");
  
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "iso_report.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  
  const renderGroupsTable = (groups) => (
    <TableContainer component={Paper} style={{ marginTop: "20px", padding: "10px" }}>
      <Typography variant="subtitle2" style={{ marginBottom: "10px" }}>
        Groups
      </Typography>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell align="center" style={{ fontWeight: "bold" }}>Merchant Group</TableCell>
            <TableCell align="center" style={{ fontWeight: "bold" }}>Merchant Group ID</TableCell>
            <TableCell align="center" style={{ fontWeight: "bold" }}>Transactions Amount</TableCell>
            <TableCell align="center" style={{ fontWeight: "bold" }}>Transactions Count</TableCell>
            <TableCell align="center" style={{ fontWeight: "bold" }}>Amount Due</TableCell>
            <TableCell align="center" style={{ fontWeight: "bold" }}>Tx MDR</TableCell>
            <TableCell align="center" style={{ fontWeight: "bold" }}>Tx Processing</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {groups.map((group, index) => (
            <TableRow key={index}>
              <TableCell align="center">{group.merchantGroup}</TableCell>
              <TableCell align="center">{group.merchantGroupID}</TableCell>
              <TableCell align="center">{group.transactionsAmount}</TableCell>
              <TableCell align="center">{group.transactionsCount}</TableCell>
              <TableCell align="center">{group.amountDue}</TableCell>
              <TableCell align="center">{group.txMDR}</TableCell>
              <TableCell align="center">{group.txProcessing}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

  const renderHoldingsTable = (holdings) => (
    <Accordion style={{ marginTop: "20px" }}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="subtitle1">Holdings</Typography>
      </AccordionSummary>
      <AccordionDetails>
        {holdings.map((holding, index) => (
          <Accordion key={index} style={{ marginBottom: "10px" }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>
                {holding.merchantHolding} - {holding.merchantHoldingID}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell align="center" style={{ fontWeight: "bold" }}>Transactions Amount</TableCell>
                    <TableCell align="center" style={{ fontWeight: "bold" }}>Transactions Count</TableCell>
                    <TableCell align="center" style={{ fontWeight: "bold" }}>Amount Due</TableCell>
                    <TableCell align="center" style={{ fontWeight: "bold" }}>Tx MDR</TableCell>
                    <TableCell align="center" style={{ fontWeight: "bold" }}>Tx Processing</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell align="center">{holding.transactionsAmount}</TableCell>
                    <TableCell align="center">{holding.transactionsCount}</TableCell>
                    <TableCell align="center">{holding.amountDue}</TableCell>
                    <TableCell align="center">{holding.txMDR}</TableCell>
                    <TableCell align="center">{holding.txProcessing}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              {holding.groups && holding.groups.group && renderGroupsTable(holding.groups.group)}
            </AccordionDetails>
          </Accordion>
        ))}
      </AccordionDetails>
    </Accordion>
  );

  return (
    <>
      <form onSubmit={handleSubmit} style={{ margin: "20px 0" }}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              id="iso-id"
              label="ISO ID"
              value={isoID || ""}
              onChange={(event) => setIsoID(event.target.value)}
              disabled 
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl fullWidth disabled> 
              <InputLabel id="currency-label">Currency Code</InputLabel>
              <Select
                labelId="currency-label"
                id="currency"
                value={currency || ""}
                label="Currency Code"
                onChange={(event) => setCurrency(event.target.value)}
                required
              >
                <MenuItem value="USD">USD</MenuItem>
                <MenuItem value="BRL">BRL</MenuItem> 
                <MenuItem value="MXN">MXN</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              id="report-date"
              label="Report Date"
              type="date"
              value={reportDate}
              onChange={handleDateChange}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                min: new Date(new Date().setDate(new Date().getDate() - 90))
                  .toISOString()
                  .split("T")[0], 
                max: new Date(new Date().setDate(new Date().getDate() - 1))
                  .toISOString()
                  .split("T")[0], 
              }}
              required
            />
          </Grid>
          <Grid item xs={12} textAlign={isMobile ? "center" : "left"} style={{ marginTop: "20px" }}>
            <Button variant="contained" type="submit" size="large">
              GENERATE
            </Button>
            {isoData.length > 0 && (
              <Button
                variant="outlined"
                style={{ marginLeft: "10px" }}
                disabled={hide}
                onClick={downloadCSV}
              >
                Download CSV
              </Button>
            )}
          </Grid>
        </Grid>
      </form>

      {isoData.map((iso, index) => (
        <Accordion key={index} style={{ marginTop: "20px" }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6">ISO Data: {iso.isoID}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <TableContainer component={Paper} style={{ marginBottom: "20px", padding: "10px" }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="center" style={{ fontWeight: "bold" }}>ISO Name</TableCell>
                    <TableCell align="center" style={{ fontWeight: "bold" }}>Transactions Amount</TableCell>
                    <TableCell align="center" style={{ fontWeight: "bold" }}>Transactions Count</TableCell>
                    <TableCell align="center" style={{ fontWeight: "bold" }}>Amount Due</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell align="center">{iso.isoName}</TableCell>
                    <TableCell align="center">{iso.transactionsAmount}</TableCell>
                    <TableCell align="center">{iso.transactionsCount}</TableCell>
                    <TableCell align="center">{iso.amountDue}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            {renderHoldingsTable(iso.holding)}
          </AccordionDetails>
        </Accordion>
      ))}
    </>
  );
};

export default IsoDailyReportForm;
