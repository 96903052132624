import * as XLSX from "xlsx";
import { enrollmentTerminal } from "../services/terminal/enrollmentTerminalService";
import { createUserInKeycloak, checkUserExists } from "../services/userService";

const parseExcelDate = (excelSerialDate) => {
  if (!isNaN(excelSerialDate) && excelSerialDate > 10000) {
    const date = XLSX.SSF.parse_date_code(excelSerialDate);
    
    if (date && date.y && date.m && date.d) {
      return `${date.y}-${String(date.m).padStart(2, "0")}-${String(date.d).padStart(2, "0")}`;
    }
  }
  return excelSerialDate;
};


export const processFile = (file, acquirerData) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = (e) => {
      try {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: "array" });
        const sheet = workbook.Sheets[workbook.SheetNames[0]];
        const cellData = XLSX.utils.sheet_to_json(sheet, {
          header: 1,
          blankrows: true,
          defval: "",
        });

        const filteredCellData = cellData.filter((row) => !row.every((cell) => cell === ""));
        const headers = filteredCellData[1];

        filteredCellData.shift();
        filteredCellData.shift();

        const dateFields = [
          "incorporationDate",
          "effectiveDate",
        ];

        const jsonData = [];

        for (let rowIndex = 0; rowIndex < filteredCellData.length; rowIndex++) {
          const row = filteredCellData[rowIndex];
          const rowData = {};
          headers.forEach((header, index) => {
            let value = row[index];
            if (dateFields.includes(header)) {
              value = parseExcelDate(value);
            }
            rowData[header] = value || null;
          });

          if (acquirerData?.isoID && (rowData["Iso"] === "False" || rowData["Iso"] === null)) {
            return reject(new Error(`Line ${rowIndex + 2}: Field "Iso" must be "True" when isoID exists.`));
          }          

          const requiredFields = [
            "Email",
            "Merchant Name",
            "Address",
            "City",
            "State",
            "Postal Code",
            "mccID",
            "MerchantID",
            "Amount Other",
            "Currency Code",
          ];

          const missingFields = requiredFields.filter((field) => !rowData[field]);
          if (missingFields.length > 0) {
            return reject(new Error(`Line ${rowIndex + 2}: The following required fields are missing: ${missingFields.join(", ")}`));
          }

          if (rowData["Postal Code"] && isNaN(Number(rowData["Postal Code"]))) {
            return reject(new Error(`Line ${rowIndex + 2}: Postcode must be numeric.`));
          }

          const terminalData = [];
          const terminalKeys = Object.keys(rowData).filter(
            (key) =>
              key.startsWith("terminalID.") ||
              key.startsWith("terminalType.") ||
              key.startsWith("terminalSerial.")
          );

          const terminalGroups = {};
          terminalKeys.forEach((key) => {
            const [type, index] = key.split(".");
            if (!terminalGroups[index]) {
              terminalGroups[index] = {};
            }
            terminalGroups[index][type] = rowData[key];
          });

          Object.values(terminalGroups).forEach((group) => {
            if (group.terminalID && group.terminalType && group.terminalSerial) {
              terminalData.push({
                terminalID: group.terminalID,
                terminalType: group.terminalType,
                terminalSerial: group.terminalSerial,
              });
            }
          });

          jsonData.push({
            acquirer: {
              iso: rowData["Iso"],
              isoID: rowData["IsoID"],
              email: rowData["Email"],
              merchantHolding: rowData["Holding"],
              merchantHoldingID: rowData["HoldingID"],
              merchantGroup: rowData["Group"],
              merchantGroupID: rowData["GroupID"],
              merchantName: rowData["Merchant Name"],
              merchantStore: rowData["Store"],
              merchantAddress: rowData["Address"],
              merchantNeighborhood: rowData["Neighborhood"],
              merchantCity: rowData["City"],
              merchantState: rowData["State"],
              merchantPostalCode: rowData["Postal Code"],
              mccID: rowData["mccID"],
              merchantID: rowData["MerchantID"],
              enableAmountOther: rowData["Amount Other"],
              terminal: {
                terminals: terminalData,
              },
            },
            merchant: {
              incorporationType: rowData["incorporationType"],
              incorporationDate: rowData["incorporationDate"],
              taxpayerID: rowData["taxpayerID"],
              legalName: rowData["legalName"],
              businessType: rowData["businessType"],
              businessPhone: rowData["businessPhone"],
              businessEmail: rowData["businessEmail"],
              amountTxProcessing: rowData["amountTxProcessing"],
              amountTxProcessingTiers: rowData["amountTxProcessingTiers"],
              percentTxProcessingTiers: rowData["percentTxProcessingTiers"],
              amountTerminalMaintenance: rowData["amountTerminalMaintenance"],
              amountTerminalMaintenanceTiers: rowData["amountTerminalMaintenanceTiers"],
              terminalMaintenanceInactive: rowData["terminalMaintenanceInactive"],
              amountTerminalSetup: rowData["amountTerminalSetup"],
              amountTerminalSetupTiers: rowData["amountTerminalSetupTiers"],
              effectiveDate: rowData["effectiveDate"],
            },
            owner: [
              {
                percentage: rowData["percentage"],
                firstName: rowData["firstName"],
                middleName: rowData["middleName"],
                lastName: rowData["lastName"],
                taxpayerID: rowData["taxpayerID"],
                id: rowData["ID"],
                idIssuer: rowData["idIssuer"],
                address: rowData["address"],
                neighborhood: rowData["neighborhood"],
                city: rowData["city"],
                county: rowData["county"],
                state: rowData["state"],
                postalCode: rowData["postalCode"],
                country: rowData["country"],
                residencyTime: rowData["residencyTime"],
                personalPhone: rowData["personalPhone"],
                personalEmail: rowData["personalEmail"],
              },
            ],
            bank: {
              accountNumber: rowData["accountNumber"],
              Iban: rowData["Iban"],
              swiftCode: rowData["swiftCode"],
              routingNumber: rowData["routingNumber"],
              branchNumber: rowData["branchNumber"],
              Bid: rowData["Bid"],
              address: rowData["address"],
              city: rowData["city"],
              state: rowData["state"],
              postalCode: rowData["postalCode"],
              countryCode: rowData["countryCode"],
            },
            fiat: {
              currencyCode: rowData["Currency Code"],
            },
          });
        }

        resolve({ filteredCellData: jsonData });
      } catch (error) {
        console.error("Error reading file:", error);
        reject(error);
      }
    };

    reader.onerror = (err) => {
      console.error("File reading error:", err);
      reject(err);
    };

    reader.readAsArrayBuffer(file);
  });
};

export const sendData = async (data, url, token, acquirerData) => {
  const successfulData = [];
  const failedData = [];

  for (const item of data) {
    try {
      const email = item.acquirer.email;
      const sanitizedUsername = item.acquirer.merchantName
        .toLowerCase()
        .replace(/\s+/g, "_")
        .replace(/[^a-z0-9_]/g, "");

      const existingUser = await checkUserExists({ username: sanitizedUsername, email });
      console.log(existingUser)
      if (existingUser) {
        failedData.push({ item, message: 'User already exists with this email or Merchant Name!' });
        continue;
      }      
      const response = await enrollmentTerminal(url, item, token, acquirerData);
      
      if (response.data) {
        const sanitizedUsername = item.acquirer.merchantName
          .toLowerCase()
          .replace(/\s+/g, "_")
          .replace(/[^a-z0-9_]/g, "");

        const rol_data = item.acquirer.iso === true || item.acquirer.iso === "True" ? "ISO" : "merchant";

        const userData = {
          username: sanitizedUsername,
          email: item.acquirer.email,
          emailVerified: false,
          enabled: true,
          credentials: [
            {
              type: "password",
              value: "123456",
              temporary: true,
            },
          ],
          attributes: {
            "acquirer-rol-data": rol_data,
            "acquirer-id-data": acquirerData.acquirerId,
            "acquirer-name-data": acquirerData.acquirerName,
            "acquirer-currency-data": item.fiat.currencyCode,
            ...(rol_data === "ISO" ? { "acquirer-id-iso-data": item.acquirer.isoID } : {}),
          },
        };

        try {
          await createUserInKeycloak(userData);
          successfulData.push({ item, response });
        } catch (keycloakError) {
          console.error("Error creating user in Keycloak:", keycloakError);
          failedData.push({ item, error: keycloakError });
        }
      }
    } catch (error) {
      console.error("Error sending data:", error);
      failedData.push({ item, error });
    }
  }

  return { successfulData, failedData };
};